<div class="card card-border d-flex justify-content-center">
    <p class="m-3">
        Import supplemental questionnaires you want answered as part of your third party review process. File type must
        be .xlsx, .xls, .xlsm, .csv to be answered automatically.<br />
        Choose if you want a questionnaire to be answered automatically by our AI engine and/or included in assessment
        collection efforts for the third party to complete.
    </p>
</div>

<div class="questionnaire-header d-flex justify-content-between align-items-center border-bottom">
    <strong>{{ numberOfQuestionnairesHeader$ | async }}</strong>
    <button
        *jhiHasAnyAuthority="Roles.OrgAdmin"
        mat-button
        visoButton="primary"
        class="float-end"
        (click)="fileInput.click()"
    >
        Import Questionnaire
    </button>
    <input #fileInput type="file" ng2FileSelect multiple class="d-none" [uploader]="uploader" />
</div>

<ng-container
    *ngIf="{
        questionnaires: supplementalQuestionnaires$ | async,
    } as ctx"
>
    <div [ngClass]="{ 'text-center': !ctx.questionnaires.length }">
        <ngx-datatable
            #questionnaireList
            class="bootstrap"
            [columnMode]="'force'"
            [rowHeight]="35"
            [headerHeight]="30"
            [rows]="ctx.questionnaires"
            [scrollbarV]="true"
            [count]="totalCurrentItems"
            [sorts]="tableSort$ | async"
            [footerHeight]="40"
            [externalSorting]="true"
            (sort)="onSort($event)"
            [externalPaging]="true"
            [virtualization]="false"
            [offset]="page"
            [limit]="itemsPerPage"
            [loadingIndicator]="questionnairesLoading"
            [messages]="{ emptyMessage: 'No questionnaires uploaded yet.' }"
            [rowClass]="getRowInactiveClass"
        >
            <ngx-datatable-column name="Name" prop="fileName">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <a
                        *ngIf="doesQuestionnaireHaveParsedQuestions(row) && isCurrentUserOrgAdmin; else fileName"
                        class="viso-link"
                        [routerLink]="[
                            '/your-organization/supplemental-questionnaire',
                            row.parsedQuestionnaireId,
                            'edit',
                        ]"
                    >
                        {{ value | trimFileExtension }}
                    </a>

                    <ng-template #fileName>
                        {{ value | trimFileExtension }}
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Uploaded On" prop="createdDate">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value | date: 'mediumDate' }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Review Needed" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <ng-container *ngIf="row.manualReviewNeeded; else manualReviewNotNeeded">
                        <div class="d-flex justify-content-center manual-review-needed-cell">
                            <mat-icon class="scale-sm text-secondary">flag</mat-icon>
                        </div>
                    </ng-container>
                    <ng-template #manualReviewNotNeeded>
                        <span class="d-flex justify-content-center">-</span>
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Questions" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{
                        getRowInactiveClass(row).inactive
                            ? 'Processing'
                            : !!row.parsedQuestionCount
                              ? row.parsedQuestionCount
                              : '-'
                    }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Include in Collection Request" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex justify-content-center">
                        <mat-slide-toggle
                            class="scale-sm"
                            color="primary"
                            [(ngModel)]="row.includeInCollectionRequest"
                            [disableRipple]="true"
                            [disabled]="!isCurrentUserOrgAdmin"
                            (toggleChange)="onIncludeInCollectionChanged(row)"
                        >
                        </mat-slide-toggle>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Answer Automatically" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="d-flex justify-content-center">
                        <mat-slide-toggle
                            class="scale-sm"
                            color="primary"
                            [(ngModel)]="row.answerAutomatically"
                            [disableRipple]="true"
                            (toggleChange)="onAnswerAutomaticallyChanged(row)"
                            [disabled]="!doesQuestionnaireHaveParsedQuestions(row) || !isCurrentUserOrgAdmin"
                        >
                        </mat-slide-toggle>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                [width]="40"
                cellClass="order-50 text-center bg-white"
                headerClass="order-50"
                [resizeable]="false"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [frozenRight]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="additional-actions" placement="bottom left" dropdown container="body">
                        <button
                            id="button-container"
                            dropdownToggle
                            type="button"
                            class="btn-icon float-end"
                            aria-controls="dropdown-container"
                        >
                            <em class="fa fa-ellipsis-v"></em>
                        </button>
                        <ul
                            id="dropdown-container"
                            *dropdownMenu
                            class="dropdown-menu dropdown-menu-end"
                            aria-labelledby="button-container"
                        >
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="downloadOriginalQuestionnaire(row)">
                                    Download Original File
                                </a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item text-danger" (click)="deleteQuestionnaire(row)">
                                    Delete Questionnaire
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template
                    ngx-datatable-footer-template
                    let-page="page"
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-curPage="curPage"
                >
                    <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="setCurrentPage($event)"
                    ></datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</ng-container>
