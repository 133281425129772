<mat-expansion-panel [expanded]="defaultExpanded">
    <mat-expansion-panel-header>
        <mat-panel-title class="flex-wrap">
            <strong class="mt-2">{{ assessmentTypeLabel }}</strong>
            <p class="text-muted full-basis" *ngIf="!!assessment">{{ interactivity }}</p>
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpanded && isAssessmentFinished">
            <div class="col">
                <div class="mt-2 d-flex align-items-center">
                    <strong class="d-block me-1">
                        {{ assessmentStatus }}
                    </strong>
                    <mat-icon
                        *ngIf="assessment?.cancelledReason === AssessmentCancelledReason.NO_LONGER_DO_BUSINESS"
                        class="text-muted"
                        [matTooltip]="assessment?.assessmentStatusReasonDetail"
                        matTooltipPosition="right"
                        [inline]="true"
                        >info
                    </mat-icon>
                </div>
                <p class="text-muted">{{ finishedDate | date }}</p>
            </div>
            <ng-container *ngIf="isAssessmentComplete">
                <div class="col-auto me-3">
                    <app-risk-chip class="ms-2" [risk]="completedResidualRisk" [condensed]="true" />
                </div>
                <div class="col-auto">
                    {{ recommendationsCount }}
                    <ng-container [ngPlural]="recommendationsCount">
                        <ng-template ngPluralCase="=0">Recommendations</ng-template>
                        <ng-template ngPluralCase="=1">Recommendation</ng-template>
                        <ng-template ngPluralCase="other">Recommendations</ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="p-0 mb-3">
        <h4 class="mt-2">Events</h4>
        <app-assessment-status-timeline [assessment]="assessment" />
    </div>

    <div *ngIf="!assessment" class="text-center py-4 bg-light start-assessment-message">
        <p class="mb-0">
            Start an assessment to understand what controls are in place to mitigate the risk of this relationship.
        </p>
        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                <ng-container *ngIf="!isRelationshipArchived">
                    <button
                        *disableStartAssessmentOnRules="relationship"
                        exportRemovableAction
                        mat-button
                        visoButton="primary"
                        class="mt-2"
                        data-pw="assessmentTimeline-startAssessment"
                        (click)="startAssessment.emit()"
                    >
                        Start Assessment
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <mat-tab-group
        mat-stretch-tabs="false"
        class="viso-tabset"
        animationDuration="0"
        dynamicHeight
        *ngIf="!!assessment"
    >
        <mat-tab *ngIf="isAssessmentComplete" label="Assessment Summary">
            <ng-template matTabContent>
                <div class="row p-3 my-3 bg-light">
                    <div class="col">
                        <div class="row my-2" *ngIf="displayAssessmentOutdatedBanner">
                            <div class="col">
                                <div class="text-bg-warning text-bold p-2">
                                    New Artifact Intelligence is available or the Relationship Context has changed,
                                    start a new assessment to have it included in the summary.
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <app-assessment-risk-diff
                                    class="d-block my-2"
                                    [completedRiskAssessment]="completedRiskAssessment"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                @if (!!recommendations.length) {
                                    <app-recommendations-list
                                        [recommendations]="recommendations"
                                        [vendorName]="vendorName"
                                    />
                                } @else {
                                    <p>There are no recommendations for this relationship.</p>
                                }
                            </div>
                        </div>
                        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin]">
                            <ng-container *ngIf="showReviewRiskButton">
                                <div class="row">
                                    <div class="col">
                                        <strong
                                            >Please review the risk in this relationship based on this
                                            assessment.</strong
                                        >
                                        <ul>
                                            <li>You’re in control of accepting the risk from this relationship.</li>
                                            <li>
                                                If you seek remediation of a vendor’s program, VISO TRUST will
                                                automatically follow up at a date of your choosing.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" exportRemovableAction>
                                    <div class="col">
                                        <button (click)="reviewRisk.emit()" visoButton="primary" mat-button>
                                            Review risk
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="row p-3 bg-light">
                    <div class="col">
                        <h4>Assessment Summary</h4>
                        <app-assessment-summary [summary]="completedSummary" />
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Email Activity">
            <app-assessment-email-history [assessmentEmails]="emails" [currentUser]="currentUser" />
        </mat-tab>
    </mat-tab-group>

    <mat-action-row exportRemovableAction>
        <ng-container *ngIf="isAssessmentInProgress">
            <ng-container *ngIf="showSendReminderEmailButton">
                <button
                    mat-button
                    visoButton="secondary"
                    color="primary"
                    class="me-auto"
                    data-pw="assessmentsHandler-inProgress-reminder"
                    (click)="sendReminderEmail.emit()"
                >
                    Send Reminder Email
                </button>
            </ng-container>
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                <button
                    *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                    mat-button
                    visoButton="secondary"
                    class="me-2"
                    [disabled]="proceedWithAvailableDataButtonIsDisabled"
                    (click)="proceedWithAvailableData.emit()"
                >
                    Proceed with available data
                    <mat-icon iconPositionEnd>fast_forward</mat-icon>
                </button>
                <button
                    *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                    toggleDisableOnActions
                    mat-button
                    visoButton="secondary"
                    color="warn"
                    class="me-2"
                    data-pw="assessmentsHandler-inProgress-cancel"
                    [disablingActions]="[AssessmentActions.CancelRequestLatestAssessmentRequest]"
                    [enablingActions]="[
                        AssessmentActions.CancelRequestLatestAssessmentRequestSuccess,
                        AssessmentActions.CancelRequestLatestAssessmentRequestFailed,
                    ]"
                    (click)="cancelAssessment.emit()"
                >
                    Cancel
                </button>
            </ng-container>
        </ng-container>
        <a
            *ngIf="showAuditorView && !!assessment"
            mat-button
            visoButton="secondary"
            data-pw="assessmentsHandler-inProgress-viewDetails"
            [routerLink]="['/assessments/', assessmentId]"
            >Auditor View
        </a>
    </mat-action-row>
</mat-expansion-panel>
