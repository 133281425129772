import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { QuestionnaireArtifact } from '@entities/artifact';
import {
    AdditionalInformationSubSteps,
    ArtifactUploadRecommendation,
    PublicAssessmentRTPFileArtifact,
} from '../../../models';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-additional-information-step',
    templateUrl: './additional-information-step.component.html',
    styleUrls: ['./additional-information-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationStepComponent {
    @Input({ required: true })
    subStep: AdditionalInformationSubSteps;

    @Input({ required: true })
    artifactUploadRecommendations: ArtifactUploadRecommendation[];

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    questionnaire: QuestionnaireArtifact;

    @Input({ required: true })
    followupRequested: boolean;

    @Input({ required: true })
    followupControlDomainIds: number[];

    @Output()
    expiredDocumentsSkipped = new EventEmitter<void>();

    @Output()
    expiredDocumentsWentBack = new EventEmitter<void>();

    @Output()
    expiredDocumentsContinued = new EventEmitter<void>();

    @Output()
    uploadRecommendationsContinued = new EventEmitter<void>();

    @Output()
    uploadRecommendationsWentBack = new EventEmitter<void>();

    @Output()
    uploadArtifact = new EventEmitter<{ recommendationId: string; file: File }>();

    @Output()
    removeArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();

    @Output()
    setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();

    @Output()
    onWhenAddingFileFailed = new EventEmitter<FilterFunction>();

    @Output()
    questionnaireWentBack = new EventEmitter<void>();

    @Output()
    questionnaireWentBackToCollectArtifacts = new EventEmitter<void>();

    @Output()
    questionnaireContinued = new EventEmitter<void>();

    @Output()
    onQuestionnaireUpdated = new EventEmitter<QuestionnaireArtifact>();

    @Output()
    updateRecommendation = new EventEmitter<ArtifactUploadRecommendation>();

    AdditionalInformationSubSteps = AdditionalInformationSubSteps;
}
