import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatConfirmDialogService } from './mat-confirm-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { VisoButtonModule } from '../../viso-button/viso-button.module';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { SafeHtmlPipeModule } from '../../safe-html-pipe/safe-html-pipe.module';

@NgModule({
    declarations: [MatConfirmDialogComponent],
    imports: [CommonModule, MatDialogModule, VisoButtonModule, SafeHtmlPipeModule],
    providers: [MatConfirmDialogService],
})
export class MatConfirmDialogModule {}
