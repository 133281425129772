<div class="row">
    <div class="col-xl-12">
        <div class="card card-default">
            <div class="card-header">
                <a class="strong">Custom Data Types </a>
                <button
                    class="float-end"
                    mat-button
                    visoButton="primary"
                    (click)="openManageCustomDataTypeModal(manageCustomConfigModal)"
                >
                    Custom Data Type
                </button>
            </div>
            <div class="card-body">
                <ngx-datatable
                    #customDataTypesConfigTable
                    class="bootstrap"
                    [rows]="customDataTypes"
                    [columnMode]="'force'"
                    [headerHeight]="$any('auto')"
                    [rowHeight]="$any('auto')"
                    [messages]="customDataTypesMessages"
                    [count]="customDataTypes.length"
                >
                    <ngx-datatable-column name="Name" prop="dataType.name">
                        <ng-template let-row="row" ngx-datatable-cell-template>{{ row.dataType.name }} </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Description" prop="dataType.description">
                        <ng-template let-row="row" ngx-datatable-cell-template
                            >{{ row.dataType.description }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Sensitivity" prop="dataType.sensitivity">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ngx-slider
                                class="viso-slider"
                                [options]="sliderOptions"
                                [(value)]="row.dataType.sensitivity"
                            ></ngx-slider>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="dataType.status">
                        <ng-template let-row="row" ngx-datatable-cell-template
                            >{{ row.enabled ? 'Enabled' : 'Disabled' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="40" [resizable]="false" [canAutoResize]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="additional-actions" placement="bottom left" dropdown container="body">
                                <button
                                    id="button-container"
                                    dropdownToggle
                                    type="button"
                                    class="btn-icon float-end"
                                    aria-controls="dropdown-container"
                                >
                                    <em class="fa fa-ellipsis-v"></em>
                                </button>
                                <ul
                                    id="dropdown-container"
                                    *dropdownMenu
                                    class="dropdown-menu dropdown-menu-start translate-x"
                                    aria-labelledby="button-container"
                                    style="--data-translate-value: -160px"
                                >
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            (click)="openManageCustomDataTypeModal(manageCustomConfigModal, row)"
                                            >Edit</a
                                        >
                                    </li>
                                    <li>
                                        <a class="dropdown-item" (click)="toggleDataTypeStatus(row)">{{
                                            row.enabled ? 'Disable' : 'Enable'
                                        }}</a>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-12">
        <div class="card card-default">
            <div class="card-header">
                <a class="strong">Default Data Types </a>
            </div>
            <div class="card-body">
                <ngx-datatable
                    #systemDataTypesConfigTable
                    class="bootstrap"
                    [rows]="systemDataTypes"
                    [columnMode]="'force'"
                    [headerHeight]="$any('auto')"
                    [rowHeight]="$any('auto')"
                    [count]="systemDataTypes.length"
                >
                    <ngx-datatable-column name="Name" prop="dataType.name">
                        <ng-template let-row="row" ngx-datatable-cell-template>{{ row.dataType.name }} </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Description" prop="dataType.description">
                        <ng-template let-row="row" ngx-datatable-cell-template
                            >{{ row.dataType.description }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Sensitivity" prop="dataType.sensitivity">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ngx-slider
                                class="viso-slider"
                                [options]="sliderOptions"
                                [(value)]="row.dataType.sensitivity"
                            ></ngx-slider>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="dataType.status">
                        <ng-template let-row="row" ngx-datatable-cell-template
                            >{{ row.enabled ? 'Enabled' : 'Disabled' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="40" [resizable]="false" [canAutoResize]="false">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="additional-actions" placement="bottom left" dropdown container="body">
                                <button
                                    id="button-container"
                                    dropdownToggle
                                    type="button"
                                    class="btn-icon float-end"
                                    aria-controls="dropdown-container"
                                >
                                    <em class="fa fa-ellipsis-v"></em>
                                </button>
                                <ul
                                    id="dropdown-container"
                                    *dropdownMenu
                                    class="dropdown-menu dropdown-menu-start translate-x"
                                    aria-labelledby="button-container"
                                    style="--data-translate-value: -160px"
                                >
                                    <li>
                                        <a class="dropdown-item" (click)="toggleDataTypeStatus(row)">{{
                                            row.enabled ? 'Disable' : 'Enable'
                                        }}</a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            (click)="openManageCustomDataTypeModal(manageCustomConfigModal, row)"
                                            >Duplicate
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>

<ng-template #manageCustomConfigModal let-modal>
    <form [formGroup]="customDataTypesForm" name="customDataTypesForm" role="form" (ngSubmit)="submitDataType()">
        <div class="modal-header">
            <div class="modal-title title-text">
                {{ currentDataTypeOrg ? 'Edit Custom Data Type' : 'New Custom Data Type' }}
            </div>
        </div>
        <div class="modal-body modal-text">
            <div class="form-group">
                <label for="name"><strong>Name</strong></label>
                <input id="name" type="text" class="form-control" formControlName="name" [disabled]="savingDataType" />
                <span class="text-danger" *ngIf="showNameRequiredError">This name is required</span>
            </div>
            <div class="form-group">
                <label for="description"><strong>Description</strong></label>
                <input
                    id="description"
                    type="text"
                    class="form-control"
                    formControlName="description"
                    [disabled]="savingDataType"
                />
                <span class="text-danger" *ngIf="showDescriptionRequiredError">This description is required</span>
            </div>
            <div class="form-group">
                <label for="exampleText"><strong>Example Text</strong></label>
                <input
                    id="exampleText"
                    type="text"
                    class="form-control"
                    formControlName="exampleText"
                    [disabled]="savingDataType"
                />
            </div>
            <div class="form-group">
                <span for="category"><strong>Category</strong></span>
                <select class="form-control" id="category" formControlName="category">
                    <option [ngValue]="null">Select a category</option>
                    <option [ngValue]="dataTypeCategory" *ngFor="let dataTypeCategory of dataTypeCategories">
                        {{ dataTypeCategory }}
                    </option>
                </select>
                <span class="text-danger" *ngIf="showCategoryRequiredError">Category is required</span>
            </div>
            <div class="form-group">
                <label for="sensitivity"><strong>Sensitivity</strong></label>
                <input
                    id="sensitivity"
                    type="number"
                    min="0"
                    max="1"
                    class="form-control"
                    formControlName="sensitivity"
                    [disabled]="savingDataType"
                />
                <span class="text-danger" *ngIf="showSensitivityRequiredError">This sensitivity is required</span>
                <span class="text-danger" *ngIf="showSensitivityValueLimitError"
                    >The sensitivity value must be between 0 and 1</span
                >
            </div>
            <label for="relevantControlDomainIds" class="filter-label"><strong>Control Domains:</strong></label>
            <ngx-select
                name="Control Domains"
                id="relevantControlDomainIds"
                class="filter-control"
                [items]="orgProfileControls$ | async"
                [multiple]="true"
                optionTextField="name"
                optionValueField="id"
                formControlName="relevantControlDomainIds"
                placeholder="Map Control Domains"
            >
            </ngx-select>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button
                type="button"
                mat-button
                visoButton="warning"
                [disabled]="savingDataType"
                (click)="closeManageCustomDataTypeModal()"
            >
                Cancel
            </button>
            <div>
                <button mat-button visoButton="primary" type="submit" [disabled]="savingDataType">
                    {{ savingDataType ? 'Saving...' : 'Submit' }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
