import { createAction, props } from '@ngrx/store';

export enum AppActions {
    SetViewport = '[App] Set Viewport',
    NOOP = 'noop',
}

export const NOOP = createAction(AppActions.NOOP);

export const setViewport = createAction(AppActions.SetViewport, props<{ width: number }>());
