import { Action, createReducer, on } from '@ngrx/store';
import { initialState, LayoutState } from './layout-state';
import { setAssessmentHeaderColor, setAssessmentLogoVisible, setLayout } from './layout.actions';

const reducer = createReducer(
    initialState,
    on(setLayout, (state, { layout }) => ({
        ...state,
        layout,
    })),
    on(setAssessmentHeaderColor, (state, { color }) => ({
        ...state,
        assessmentHeaderColor: color,
    })),
    on(setAssessmentLogoVisible, (state, { visible }) => ({
        ...state,
        assessmentLogoVisible: visible,
    })),
);

export function layoutReducer(state: LayoutState = initialState, action: Action): LayoutState {
    return reducer(state, action);
}
