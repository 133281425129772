<div class="row mb-2">
    <div class="col">
        <h3 class="lead">Lifecycle Management</h3>
    </div>
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <ng-container *ngIf="!(userIsTrial$ | async) && isOnboarded">
            <div class="col-auto">
                <a
                    *jhiHasNotAuthority="[Roles.Auditor, Roles.Support]"
                    exportRemovableAction
                    class="text-primary clickable"
                    (click)="openLifecycleManagementModal()"
                >
                    Edit
                </a>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="isOnboarded; else notOnboarded">
    <div class="row">
        <div class="col">
            <div class="text-bold">Recertification</div>
            <p>
                {{ recertificationLabel$ | async }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="text-bold">Artifact Updates</div>
            <p>{{ artifactUpdateLabel$ | async }}</p>
        </div>
    </div>
</ng-container>
<ng-template #notOnboarded>
    <p class="text-muted">Onboard to enable this feature</p>
</ng-template>
