import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { take, tap } from 'rxjs';
import { Relationship, RequestStatus } from '@entities/relationship';
import { Email } from '@entities/email';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { Risk, SlimRiskAssessment } from '@entities/risk-assessment';
import { AssessmentRecommendation, RecommendationType } from '@entities/recommendation';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import {
    AssessmentCreatedReasonLabel,
    AssessmentStatus,
    AssessmentStatusLabels,
    AssessmentTypeLabels,
    VendorCancelReason,
} from '@entities/assessment';
import { PopulatedAssessment } from '../../models/populated-assessment';
import { AssessmentActions } from '../../redux/actions/assessments.actions';
import { getCurrentEnvironment } from '@shared/utils/environment-utils';
import { Environments } from '../../../../layout/sidebar/menu';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';

@Component({
    selector: 'app-assessment-list-item',
    templateUrl: './assessment-list-item.component.html',
    styleUrls: ['./assessment-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentListItemComponent implements AfterViewInit {
    @Input({ required: true })
    relationship: Relationship;

    @Input({ required: true })
    assessment: PopulatedAssessment;

    @Input({ required: true })
    assessmentSummarySectionConfig: OrgAssessmentSummarySections[];

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    currentUser: VisoUser;

    @Input()
    defaultExpanded: boolean;

    @Output()
    firstOpen = new EventEmitter<number>();

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    cancelAssessment = new EventEmitter<void>();

    @Output()
    sendReminderEmail = new EventEmitter<void>();

    @Output()
    proceedWithAvailableData = new EventEmitter<void>();

    @Output()
    reviewRisk = new EventEmitter<void>();

    Roles = VisoUserRole;
    AssessmentActions = AssessmentActions;
    AssessmentCancelledReason = VendorCancelReason;

    @ViewChild(MatExpansionPanel)
    private _expansionPanel: MatExpansionPanel;

    constructor(private _assessmentUtils: AssessmentUtilsService) {}

    ngAfterViewInit(): void {
        this._expansionPanel.opened
            .pipe(
                tap(() => this.firstOpen.emit(this.assessment?.id)),
                take(1),
            )
            .subscribe();
    }

    get isLatestAssessment(): boolean {
        return this.relationship?.latestAssessmentId === this.assessmentId;
    }

    get isExpanded(): boolean {
        return this._expansionPanel?.expanded;
    }

    get assessmentId(): number {
        return this.assessment?.id;
    }

    get showAuditorView(): boolean {
        // We should always show the assessment details to auditors and support in PROD
        // For lower environments, we can show the details to everyone
        const allowedRoles = [VisoUserRole.Auditor, VisoUserRole.Support];
        return getCurrentEnvironment() !== Environments.PROD
            ? true
            : this.currentUser?.authorities.some((role) => allowedRoles.includes(role));
    }

    get assessmentTypeLabel(): string {
        return !!this.assessment?.type
            ? AssessmentTypeLabels[this.assessment?.type] +
                  ' - ' +
                  AssessmentCreatedReasonLabel[this.assessment?.createdReason]
            : null;
    }

    get showSendReminderEmailButton(): boolean {
        return (
            !this.assessment?.documentsOnly &&
            this._assessmentUtils.isAssessmentStartedOrCollectingInformation(this.assessment?.status)
        );
    }

    get interactivity(): string {
        return this.assessment?.documentsOnly ? 'Non-interactive' : 'Interactive';
    }

    get isAssessmentInProgress(): boolean {
        return this._assessmentUtils.isAssessmentInProgress(this.assessment?.status);
    }

    get isAssessmentFinished(): boolean {
        return this._assessmentUtils.isAssessmentFinished(this.assessment?.status);
    }

    get isAssessmentCancelled(): boolean {
        return this._assessmentUtils.isAssessmentCancelled(this.assessment?.status);
    }

    get isAssessmentComplete(): boolean {
        return this._assessmentUtils.isAssessmentComplete(this.assessment?.status);
    }

    get isAssessmentReviewStarted(): boolean {
        return this._assessmentUtils.isAssessmentReviewStarted(this.assessment?.status);
    }

    get finishedDate(): Date {
        return this.isAssessmentComplete
            ? this.assessment?.completedDate
            : this.isAssessmentCancelled
              ? this.assessment?.statusHistories?.find(({ status }) => status === AssessmentStatus.CANCELLED)?.date
              : null;
    }

    get assessmentStatus(): string {
        return AssessmentStatusLabels[this.assessment?.status];
    }

    get completedResidualRisk(): Risk {
        return this.isAssessmentComplete ? this.assessment?.completedRiskAssessment?.risk : null;
    }

    get recommendationsCount(): number {
        return this.isAssessmentComplete ? this.recommendations?.length : null;
    }

    get emails(): Email[] {
        return this.assessment?.emails;
    }

    get completedSummary(): string {
        const isUserAuditor = this.currentUser?.authorities.some((role) => [VisoUserRole.Auditor].includes(role));
        return isUserAuditor
            ? this.assessment?.completedSummary
            : this._assessmentUtils.getAssessmentSummaryForClientConfiguration(
                  this.assessment?.completedSummary,
                  this.assessmentSummarySectionConfig,
              );
    }

    get completedRiskAssessment(): SlimRiskAssessment {
        return this.assessment?.completedRiskAssessment;
    }

    get recommendations(): AssessmentRecommendation[] {
        return (
            this.assessment?.recommendations?.filter(
                (recommendation) => recommendation.type !== RecommendationType.COMBINED,
            ) || []
        );
    }

    get vendorName(): string {
        return this.relationship?.vendorName;
    }

    get isRelationshipArchived(): boolean {
        return this.relationship?.status === RequestStatus.ARCHIVED;
    }

    get displayAssessmentOutdatedBanner(): boolean {
        const completedRiskAssessmentScore =
            this.assessment?.completedRiskAssessment?.riskScores.SECURITY?.residualRiskScore ??
            this.assessment?.completedRiskAssessment?.score;
        const latestRiskAssessmentScore = this.relationship?.latestRiskAssessment.riskScores.SECURITY.residualRiskScore;
        return this.isLatestAssessment && completedRiskAssessmentScore !== latestRiskAssessmentScore;
    }

    get proceedWithAvailableDataButtonIsDisabled(): boolean {
        return !(
            this.assessment?.artifacts?.length > 0 &&
            this.isAssessmentInProgress &&
            !this.isAssessmentReviewStarted
        );
    }

    get showReviewRiskButton(): boolean {
        return this.isLatestAssessment && this.isAssessmentComplete;
    }
}
