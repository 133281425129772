<div class="wrapper" *ngIf="layout$ | async as layout">
    <app-header class="topnavbar-wrapper" *ngIf="layout === LayoutType.Full" />
    <app-sidebar class="aside-container" *ngIf="layout === LayoutType.Full" />
    <app-basic-header class="topnavbar-wrapper" *ngIf="layout === LayoutType.Basic" />
    <app-assessment-header class="topnavbar-wrapper" *ngIf="layout === LayoutType.Assessment" />

    <main
        class="section-container"
        [ngClass]="{
            'ms-0': layout === LayoutType.Basic,
            'm-0 no-footer': layout === LayoutType.Blank,
        }"
    >
        <div class="content-wrapper" [ngClass]="{ 'p-0 minh-100vh': layout === LayoutType.Blank }">
            <router-outlet></router-outlet>
            <router-outlet name="popup"></router-outlet>
        </div>
    </main>
</div>
