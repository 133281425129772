import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ITEMS_PER_PAGE } from '../../../shared';
import { BehaviorSubject, EMPTY, fromEvent, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { VendorRelationshipsService } from '../../../routes/relationships/relationships.service';
import { VendorRelationship } from '../../../routes/relationships/models/relationship.model';
import { navsearchAnimation } from '../../../shared/animation/navsearch.animation';

@Component({
    selector: 'app-navsearch',
    templateUrl: './navsearch.component.html',
    styleUrls: ['./navsearch.component.scss'],
    animations: navsearchAnimation,
})
export class NavsearchComponent implements OnInit, OnDestroy {
    @Input() visible: boolean;
    relationships: VendorRelationship[];
    itemsPerPage: number;
    links: any;
    page: any;
    predicate: any;
    reverse: any;
    totalItems: number;

    expanded: boolean = false;
    searchText: string = '';
    searchStream = new BehaviorSubject('');
    vendorSelected = false;

    private _unsub = new Subject<void>();

    constructor(
        public elem: ElementRef,
        public relationshipsService: VendorRelationshipsService,
    ) {
        this.relationships = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;
        this.predicate = 'id';
        this.reverse = true;
    }

    ngOnInit() {
        fromEvent(window, 'click')
            .pipe(
                map((e: any) => {
                    if (this.expanded && this.vendorSelected) {
                        this.vendorSelected = false;
                        return;
                    }
                    const isClickInside = this.elem.nativeElement.contains(e.target);
                    if (!isClickInside) {
                        this.closeNavSearch();
                    }
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this.searchStream
            .pipe(
                takeUntil(this._unsub),
                debounceTime(200),
                map((searchText) => searchText.trim()),
                distinctUntilChanged(),
                switchMap((searchText) => {
                    if (searchText.length > 1) {
                        return this.relationshipsService
                            .query({
                                search: encodeURI(
                                    `drStatus:[NOT_ONBOARDED|ONBOARDED],text~${searchText.toLowerCase()}`,
                                ),
                                sort: this.sort(),
                            })
                            .pipe(
                                map((res: HttpResponse<VendorRelationship[]>) => this.onSuccess(res.body, res.headers)),
                                catchError(() => of(null)),
                            );
                    } else {
                        this.relationships = [];
                        return EMPTY;
                    }
                }),
            )
            .subscribe();
    }

    search(search: string) {
        this.searchStream.next(search);
    }

    displayVendorFn(relationship: VendorRelationship): string {
        return (relationship && relationship.vendorOrgName) || '';
    }

    closeNavSearch() {
        this.relationships = [];
        this.searchText = '';
        this.expanded = false;
    }

    resetControl() {
        this.relationships = [];
        setTimeout(() => {
            this.searchText = '';
        }, 100);
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    private onSuccess(data, headers) {
        this.totalItems = headers.get('X-Total-Count');
        this.relationships = data;
    }

    close() {
        this.searchText = '';
        this.relationships = [];
    }

    onSearchClicked(inputRef: HTMLInputElement) {
        if (!this.expanded) {
            this.expanded = true;
            setTimeout(() => {
                inputRef.focus();
            }, 200);
        }
    }

    listenKeyUp($event: KeyboardEvent) {
        if ($event.code === 'Escape') {
            this.closeNavSearch();
        }
    }
}
