import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { VisoUserRole } from '@entities/viso-user';
import { RiskModelSettingsComponent } from './risk-model-settings.component';
import { FeatureFlagGuard } from '@shared/guards/feature-flag.guard';
import { FeatureFlags } from '@shared/enums/feature-flags';

export const riskModelSettingsRoutes: Routes = [
    {
        path: 'risk-model-settings',
        component: RiskModelSettingsComponent,
        data: {
            featureFlag: FeatureFlags.RISK_TOLERANCE,
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
            pageTitle: 'Risk model',
        },
        canActivate: [AuthGuard, FeatureFlagGuard],
    },
];
