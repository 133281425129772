<div class="row">
    <div class="col">
        <mat-card appearance="outlined" class="risk-tolerance-card">
            <mat-card-header>
                <mat-card-title
                    >Your risk tolerance is {{ RiskToleranceLabels[clientRiskTolerance] | lowercase }}
                    {{ clientRiskTolerance === RiskTolerance.MINIMAL ? '(default)' : '' }}
                </mat-card-title>
                <mat-card-subtitle>
                    <p>{{ clientRiskTolerance | callback: getRiskToleranceSummary }}</p>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row mt-4">
                    <div class="col">
                        <app-risk-thresholds
                            [riskLevelDisplayNames]="riskLevelDisplayNames"
                            [riskToleranceThresholds]="riskToleranceThresholds"
                            [activeRiskTolerance]="clientRiskTolerance"
                        ></app-risk-thresholds>
                    </div>

                    <div class="col-auto">
                        <mat-divider class="vertical-divider" [vertical]="true" />
                    </div>

                    <div class="col">
                        <app-risk-distribution
                            [riskToleranceThresholds]="riskToleranceThresholds"
                            [activeRiskTolerance]="clientRiskTolerance"
                            [riskLevelDisplayNames]="riskLevelDisplayNames"
                            [relationshipRiskDistribution]="relationshipRiskDistribution"
                        ></app-risk-distribution>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end" class="mt-3">
                <button mat-button visoButton="primary" (click)="customizeRiskTolerance.emit()">Customize</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
