import { BaseEntity } from '../../shared';
import { DataType } from '../../entities/data-type';
import { ContextType } from '../../entities/context-type';
import { Artifact, ControlValidation, FileArtifact } from '../../entities/artifact';
import { VisoUser } from '../../entities/viso-user';
import { SlimRiskAssessment } from '../../entities/risk-assessment';
import { ControlDomainType } from '../../entities/control-domain';
import { AssessmentRecommendation } from '../../entities/recommendation';
import { RemediationRequest } from '../remediation-request/remediation-request.model';
import { AttestationType, AttestationValue } from '../attestation/attestation.model';

export enum AssessmentStatus {
    NOT_ASSESSED = 'NOT_ASSESSED',
    STARTED = 'STARTED',
    REVIEW_STARTED = 'REVIEW_STARTED',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    DELETED = 'DELETED',
    COLLECTING_INFORMATION = 'COLLECTING_INFORMATION',
    AWAITING_REMEDIATION = 'AWAITING_REMEDIATION',
}

export enum AssessmentStatusLabels {
    NOT_ASSESSED = 'Not Assessed',
    STARTED = 'Started',
    REVIEW_STARTED = 'Review Started',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    DELETED = 'Deleted',
    COLLECTING_INFORMATION = 'Collecting Information',
}

export enum AssessmentType {
    CERTIFICATION = 'certification',
    ARTIFACT_UPDATE = 'artifact-update',
    RECERTIFICATION = 're-certification',
    SELF_ASSESSMENT = 'self-assessment',
    REMEDIATION = 'remediation',
}

export enum AssessmentTypeLabels {
    'certification' = 'Certification Assessment',
    'artifact-update' = 'Artifact Update Assessment',
    're-certification' = 'Recertification Assessment',
    'self-assessment' = 'Self-Assessment',
    'remediation' = 'Remediation Assessment',
}

export type AssessmentCancelledReason = AuditorCancelReason | VendorCancelReason;

export enum AuditorCancelReason {
    NO_DOCS = 'NO_DOCS',
    NO_RESPONSE = 'NO_RESPONSE',
    INVALID_DOCS = 'INVALID_DOCS',
    OTHER = 'OTHER',
}

export enum AuditorCancelReasonLabels {
    NO_DOCS = 'No documents received',
    NO_RESPONSE = 'Third party never responded',
    INVALID_DOCS = 'Documents are invalid',
    OTHER = 'Other - check comments for details',
}

export enum VendorCancelReason {
    NO_LONGER_DO_BUSINESS = 'NO_LONGER_DO_BUSINESS',
}

export enum QuestionnaireAnswerType {
    IN_PLACE = 'IN_PLACE',
    DONT_DO = 'DONT_DO',
    SHARED_RESPONSIBILITY = 'SHARED_RESPONSIBILITY',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum QuestionnaireAnswerTypeTabLabels {
    ALL = 'All Responses',
    IN_PLACE = 'Present',
    DONT_DO = 'Insufficient',
    SHARED_RESPONSIBILITY = 'Shared Responsibility Model',
    NOT_APPLICABLE = 'Not Applicable',
    NO_ANSWER = 'No Answer Provided',
}

export enum QuestionnaireAnswerTypeLabels {
    IN_PLACE = 'This is in place per guidance',
    DONT_DO = "We don't do this",
    SHARED_RESPONSIBILITY = 'Shared responsibility model',
    NOT_APPLICABLE = 'Not applicable',
}

export const QUESTIONNAIRE_RESPONSE_TYPES = [
    {
        enumValue: QuestionnaireAnswerType.IN_PLACE,
        name: QuestionnaireAnswerTypeLabels.IN_PLACE,
    },
    {
        enumValue: QuestionnaireAnswerType.DONT_DO,
        name: QuestionnaireAnswerTypeLabels.DONT_DO,
    },
    {
        enumValue: QuestionnaireAnswerType.SHARED_RESPONSIBILITY,
        name: QuestionnaireAnswerTypeLabels.SHARED_RESPONSIBILITY,
    },
    {
        enumValue: QuestionnaireAnswerType.NOT_APPLICABLE,
        name: QuestionnaireAnswerTypeLabels.NOT_APPLICABLE,
    },
];

export enum AssessmentCreatedReason {
    INITIAL_ASSESSMENT = 'INITIAL_ASSESSMENT',
    ARTIFACTS_EXPIRED = 'ARTIFACTS_EXPIRED',
    RECERTIFICATION_INITIATED = 'RECERTIFICATION_INITIATED',
    BUSINESS_CASE_OR_DATA_TYPES_CHANGED = 'BUSINESS_CASE_OR_DATA_TYPES_CHANGED',
    THIRD_PARTY_CONTACT_UPDATED = 'THIRD_PARTY_CONTACT_UPDATED',
    ARTIFACTS_UPLOADED_ON_RELATIONSHIP = 'ARTIFACTS_UPLOADED_ON_RELATIONSHIP',
    REMEDIATION = 'REMEDIATION',
}

export enum AssessmentCreatedReasonLabel {
    INITIAL_ASSESSMENT = 'Client initiated',
    ARTIFACTS_EXPIRED = 'Artifacts expired',
    RECERTIFICATION_INITIATED = 'Recertification initiated',
    BUSINESS_CASE_OR_DATA_TYPES_CHANGED = 'Relationship context changed',
    THIRD_PARTY_CONTACT_UPDATED = 'Third party contact updated',
    ARTIFACTS_UPLOADED_ON_RELATIONSHIP = 'Artifacts submitted',
    REMEDIATION = 'Remediation initiated',
}

export enum CollectingInformationReason {
    FOLLOW_UP_SENT = 'FOLLOW_UP_SENT',
    ADDITIONAL_ARTIFACT_REQUESTED = 'ADDITIONAL_ARTIFACT_REQUESTED',
    ARTIFACT_PASSWORD_NEEDED = 'ARTIFACT_PASSWORD_NEEDED',
    ARTIFACT_FORMATTING_ISSUE = 'ARTIFACT_FORMATTING_ISSUE',
    NDA_REQUESTED = 'NDA_REQUESTED',
    RELATIONSHIP_CLARIFICATION_REQUIRED = 'RELATIONSHIP_CLARIFICATION_REQUIRED',
}

export enum CollectingInformationReasonLabels {
    FOLLOW_UP_SENT = 'Follow-up questionnaire sent',
    ADDITIONAL_ARTIFACT_REQUESTED = 'Additional artifact requested',
    ARTIFACT_PASSWORD_NEEDED = 'Artifact password needed',
    ARTIFACT_FORMATTING_ISSUE = 'Artifact formatting issue',
    NDA_REQUESTED = 'NDA requested',
    RELATIONSHIP_CLARIFICATION_REQUIRED = 'Relationship clarification required',
}

export class AssessmentDetailsView implements BaseEntity {
    constructor(
        public id: number,
        public type: AssessmentType,
        public token: string,
        public secret: string,
        public documentsOnly: boolean,
        public relationshipId: number,
        public clientId: number,
        public status: AssessmentStatus,
        public completedSummary: string,
        public generatedSummary: string,
        public senderEmail: string,
        public senderFirstName: string,
        public senderLastName: string,
        public vendorName: string,
        public createdDate: Date,
        public updatedDate: Date,
        public completedDate: Date,
        public expirationDate: Date,
        public createdReason: AssessmentCreatedReason,
        public statusHistories: StatusHistory[],
        public publicDocumentsInfo?: string,
        public sentToEmail?: string,
        public sentToFirstName?: string,
        public sentToLastName?: string,
        public submittedByEmail?: string,
    ) {}
}

export class AssessmentView implements BaseEntity {
    constructor(
        public id?: number,
        public relationshipId?: number,
        public token?: string,
        public senderEmail?: string,
        public senderFirstName?: string,
        public senderLastName?: string,
        public completedByFirstName?: string,
        public completedByLastName?: string,
        public completedByEmail?: string,
        public submittedByFirstName?: string,
        public submittedByLastName?: string,
        public submittedByEmail?: string,
        public sentToFirstName?: string,
        public sentToLastName?: string,
        public sentToEmail?: string,
        public secret?: string,
        public createdDate?: Date,
        public updatedDate?: Date,
        public phaseDate?: Date,
        public completedDate?: Date,
        public expirationDate?: string | Date,
        public status?: AssessmentStatus,
        public type?: AssessmentType,
        public clientId?: number,
        public clientName?: string,
        public vendorId?: number,
        public vendorName?: string,
        public artifacts?: Artifact[],
        public publicDocumentsInfo?: string,
        public documentsOnly?: boolean,
        public readonly hasQuestionnaire?: boolean,
        public readonly hasFollowup?: boolean,
        public readonly hasFiles?: boolean,
        public readonly questionnaireOnly?: boolean,
        public readonly filesOnly?: boolean,
        public readonly hasVisoOwned?: boolean,
        public readonly hasClientOwned?: boolean,
        public readonly hasVendorOwned?: boolean,
        public readonly visoOwnedOnly?: boolean,
        public readonly clientOwnedOnly?: boolean,
        public readonly vendorOwnedOnly?: boolean,
        public assessmentStatusReasonDetail?: string,
        public createdReason?: AssessmentCreatedReason,
        public incompleteReason?: AssessmentCreatedReason,
        public statusHistories?: StatusHistory[],
        public completedSummary?: string,
        public hasAuditDocument?: boolean,
        public hasPenetrationTest?: boolean,
        public hasSupplementalAttestation?: boolean,
        public completedRiskAssessment?: SlimRiskAssessment,
        public recommendations?: AssessmentRecommendation[],
        public cancelledReason?: AssessmentCancelledReason,
        public continuedByEmail?: string,
        public remediationRequest?: RemediationRequest,
        public collectingInformationReason?: CollectingInformationReason,
    ) {}
}

export class Assessment implements BaseEntity {
    constructor(
        public id?: number,
        public relationshipId?: number,
        public token?: string,
        public completedByFirstName?: string,
        public completedByLastName?: string,
        public completedByEmail?: string,
        public submittedByFirstName?: string,
        public submittedByLastName?: string,
        public submittedByPhone?: string,
        public submittedByEmail?: string,
        public sentToFirstName?: string,
        public sentToLastName?: string,
        public sentToEmail?: string,
        public continuedByEmail?: string,
        public secret?: string,
        public createdDate?: Date,
        public updatedDate?: Date,
        public phaseDate?: Date,
        public completedDate?: Date,
        public expirationDate?: string | Date,
        public status?: AssessmentStatus,
        public type?: AssessmentType,
        public clientId?: number,
        public clientName?: string,
        public vendorName?: string,
        public artifacts?: Artifact[],
        public documentsOnly?: boolean,
        public readonly hasQuestionnaire?: boolean,
        public readonly hasFollowup?: boolean,
        public readonly hasFiles?: boolean,
        public readonly questionnaireOnly?: boolean,
        public readonly filesOnly?: boolean,
        public readonly hasVisoOwned?: boolean,
        public readonly hasClientOwned?: boolean,
        public readonly hasVendorOwned?: boolean,
        public readonly visoOwnedOnly?: boolean,
        public readonly clientOwnedOnly?: boolean,
        public readonly vendorOwnedOnly?: boolean,
        public assessmentStatusReasonDetail?: string,
        public createdReason?: AssessmentCreatedReason,
        public incompleteReason?: AssessmentCreatedReason,
        public sender?: VisoUser,
        public senderId?: number,
        public publicDocumentsInfo?: string,
        public statusHistories?: StatusHistory[],
        public summary?: string,
        public hasAuditDocument?: boolean,
        public hasPenetrationTest?: boolean,
        public hasSupplementalAttestation?: boolean,
        public auditDocumentCheckbox?: boolean,
        public penTestCheckbox?: boolean,
        public supplementalAttestationCheckbox?: boolean,
        public readonly recommendations?: AssessmentRecommendation[],
    ) {}
}

export class PublicAssessment {
    constructor(
        public token: string,
        public questions: Array<object>,
        public status: AssessmentStatus,
        public type: AssessmentType,
        public previouslyReviewStarted: boolean,
        public clientId: number,
        public clientName: string,
        public clientLogoUrl: string,
        public clientBrandingColor: string,
        public senderEmail: string,
        public createdDate: any,
        public expireDate: any,
        public supplementalQuestionnaireLinks: string[],
        public supplementalQuestionnaireContactEmail: string,
        public artifacts?: FileArtifact[],
        public dataTypes?: DataType[],
        public contextTypes?: ContextType[],
        public description?: string,
        public readonly hasQuestionnaire?: boolean,
        public readonly hasFollowup?: boolean,
        public readonly hasFiles?: boolean,
        public readonly questionnaireOnly?: boolean,
        public readonly filesOnly?: boolean,
        public readonly hasVisoOwned?: boolean,
        public readonly hasClientOwned?: boolean,
        public readonly hasVendorOwned?: boolean,
        public readonly visoOwnedOnly?: boolean,
        public readonly clientOwnedOnly?: boolean,
        public readonly vendorOwnedOnly?: boolean,
        public hasAuditDocument?: boolean,
        public hasPenetrationTest?: boolean,
        public hasSupplementalAttestation?: boolean,
        public auditDocumentCheckbox?: boolean,
        public penTestCheckbox?: boolean,
        public supplementalAttestationCheckbox?: boolean,
        public vendorName?: string,
        public inScopeControlDomains?: Record<number, string>,
        public inScopeControlDomainTypes?: ControlDomainType[],
        public followupControlDomainIds?: number[],
        public clientSupportEmail?: string,
        public readonly recommendations?: AssessmentRecommendation[],
        public readonly previousAssessmentRecommendations?: AssessmentRecommendation[],
        public cancelledReason?: AssessmentCancelledReason,
        public sentToEmail?: string,
        public submittedByEmail?: string,
        public followupRequested?: boolean,
        public remediationTargetDate?: Date,
        public expiredArtifactReplacements?: Map<number, number>,
    ) {}
}

export class AssessmentSubmission {
    constructor(
        public assessmentToken: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public secret: string,
        public tosAccepted: boolean,
        public notifyClient: boolean,
        public attestations?: Partial<Record<AttestationType, AttestationValue>>,
        public siblingArtifactAttestations?: Partial<Record<number, AttestationValue>>,
        public expiredArtifactAttestations?: Partial<Record<number, AttestationValue>>,
    ) {}
}

export interface QuestionnaireAnswer {
    id: string;
    artifactId: number;
    question: string;
    answer?: string;
    answerType?: QuestionnaireAnswerType;
    controlDomainDescription: string;
    controlDomainType: ControlDomainType;
    controlDomainRelevantLink: string;
    questionId: number;
    controlDomainId: number;
    controlDomainName: string;
    controlId: number;
    controlName: string;
    manualReviewNeeded: boolean;
    controlValidation?: ControlValidation;
    referencedArtifactId?: number;

    //FE Only
    optional?: boolean;
    guidancePopover?: boolean;
    invalid?: boolean;
    editQuestionState?: boolean;
    allowEditQuestion?: boolean;
    followupNeeded?: boolean;
}

export interface StatusHistory {
    date: Date;
    status: AssessmentStatus;
}

export interface AssessmentEvent {
    id: number;
    createdDate: Date;
    event: {
        text: string;
        orgId: number;
        recordId: number;
    };
    eventType: any;
    orgId: number;
    recordId: number;
}

export interface CreateAssessmentQuestionnaireRequest {
    secret: string;
    token: string;
    controlDomainTypes?: ControlDomainType[];
}

export interface OpenAssessmentEmailViewParams {
    assessmentId: number;
    messageId: string;
}
