import { Routes } from '@angular/router';
import { VisoUserRole } from '../../entities/viso-user';

import { AuthGuard } from '../../shared';
import { UserProfileComponent } from './user-profile.component';

export const userProfileRoutes: Routes = [
    {
        path: 'user-profile/:id',
        component: UserProfileComponent,
        data: {
            authorities: [
                VisoUserRole.Admin,
                VisoUserRole.Auditor,
                VisoUserRole.OrgAdmin,
                VisoUserRole.Support,
                VisoUserRole.OrgUser,
            ],
            pageTitle: 'User Profile',
        },
        canActivate: [AuthGuard],
    },
];
