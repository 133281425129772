import { LayoutType } from '../../shared/model/layout-type';

export const layoutStateKey = 'layout';

export const initialState: LayoutState = {
    layout: LayoutType.Full,
    assessmentHeaderColor: undefined,
    assessmentLogoVisible: true,
};

export interface LayoutState {
    layout: LayoutType;
    assessmentHeaderColor?: string | null;
    assessmentLogoVisible: boolean;
}
