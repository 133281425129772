export enum FeatureFlags {
    TRUST = 'trust',
    PRIVACY_MODULE = 'privacy-module',
    SUPPLEMENTAL_QUESTIONNAIRES = 'supplemental-questionnaires',
    RDP_AI_QA = 'rdp-ai-qa',
    ORG_USER_CAN_ONBOARD = 'org-user-can-onboard',
    BIG_PICTURE_PUBLIC_SEARCH_API = 'big-picture-public-search-api',
    RISK_TOLERANCE = 'risk-tolerance',
    DOMAINS_BRANDING = 'domains-branding',
}
