import { NgModule, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/en';

import { VisoSharedLibsModule } from './';
import { PendingChangesGuard } from './can-deactivate.component';

@NgModule({
    imports: [VisoSharedLibsModule],
    providers: [
        PendingChangesGuard,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en',
        },
    ],
    exports: [VisoSharedLibsModule],
})
export class VisoSharedCommonModule {
    constructor() {
        registerLocaleData(locale);
    }
}
