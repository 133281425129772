<div class="comment-handler-wrapper mt-2">
    <app-create-comment
        class="d-block mb-4 d-print-none"
        *jhiHasNotAuthority="Roles.Admin"
        [cleanForm]="cleanForm$ | async"
        [mentions]="mentions"
        [fastMentions]="fastMentions"
        (createComment)="createComment.emit($event)"
    >
    </app-create-comment>
    <app-comment-list
        [comments]="comments"
        [currentAccount]="currentAccount"
        (deleteComment)="deleteComment.emit($event)"
    ></app-comment-list>
</div>
