import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LicenseType } from '../../entities/viso-user';
import { getUserAccount } from '../../routes/session/redux/session.selectors';
import { AppState } from '../redux/state';

@Injectable({
    providedIn: 'root',
})
export class LockedFeatureGuard {
    constructor(
        private _router: Router,
        private _store$: Store<AppState>,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const lockOnTrial = route.data?.lockOnTrial;
        if (lockOnTrial) {
            return this._store$.pipe(
                select(getUserAccount),
                map((account) => account?.clientLicense?.licenseType !== LicenseType.TRIAL),
                tap((isNotTrial) => {
                    if (!isNotTrial) {
                        this._router.navigate(['/']);
                    }
                }),
            );
        }
        return of(true);
    }
}
