import { FileArtifactType } from '@entities/artifact';
import { AttestationType, AttestationValue } from '@entities/attestation';
import { AuditReportTypeCode, AuditReportTypeName } from '@entities/audit-report';
import { PublicAssessmentRTPFileArtifact } from './public-assessment-rtp-file-artifact';
import { expectedArtifactLabels } from './labels';

export function generateExpiredDocumentationRecommendationId(artifactId: number): string {
    return `${AttestationType.UPDATED_ARTIFACTS}-${artifactId}`;
}

export function generateSiblingDocumentationRecommendationId(artifactId: number): string {
    return `${AttestationType.SIBLING_DOCS}-${artifactId}`;
}

export function formatThirdPartyProvidedAuditReportType(
    recommendation: ArtifactUploadRecommendation,
    fileName: string,
): string {
    if (isExpiredDocumentationRecommendation(recommendation)) {
        const suffix = recommendation.expiredAuditType !== AuditReportTypeCode.PCIDSSAOCQSA ? ` - ${fileName}` : '';
        return `${AuditReportTypeName[recommendation.expiredAuditType]}${suffix}`;
    }
    if (isSiblingDocumentationRecommendation(recommendation)) {
        return `${AuditReportTypeName[recommendation.requiredSiblingAuditType]} - ${fileName}`;
    }
    return recommendation.artifactThirdPartyProvidedAuditType;
}

export interface ArtifactUploadRecommendation {
    id: string;
    title: string;
    subtitle?: string;
    expectedArtifactsLabels: ReadonlyArray<string>;
    attestationType: AttestationType;
    attestationValue: AttestationValue;
    attestationArtifact: PublicAssessmentRTPFileArtifact;
    fileArtifactType: FileArtifactType;
    artifactThirdPartyProvidedAuditType: string;
}

export class ThirdPartyAuditRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.THIRD_PARTY_AUDITS;
    readonly title = 'Do you have any third party audits?';
    readonly subtitle = 'Third party audits provide a high level of confidence that you have controls in place.';
    readonly fileArtifactType = FileArtifactType.AUDIT_REPORT;
    readonly artifactThirdPartyProvidedAuditType = null;
    readonly id = this.attestationType;
    readonly expectedArtifactsLabels = expectedArtifactLabels.AUDIT_REPORT;

    attestationArtifact: PublicAssessmentRTPFileArtifact;
    attestationValue: AttestationValue;
}

export class ThirdPartyPenTestRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.PEN_TESTS;
    readonly title = 'Do you have any third party penetration tests?';
    readonly fileArtifactType = FileArtifactType.ASSESSMENT;
    readonly artifactThirdPartyProvidedAuditType = AuditReportTypeName[AuditReportTypeCode.PENTEST];
    readonly id = this.attestationType;
    readonly expectedArtifactsLabels = expectedArtifactLabels.ASSESSMENT;

    attestationArtifact = null;
    attestationValue = null;
}

export class PrivacyRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.PRIVACY_DOCS;
    readonly title = 'Do you have any privacy documentation?';
    readonly fileArtifactType = FileArtifactType.PRIVACY;
    readonly artifactThirdPartyProvidedAuditType = null;
    readonly id = this.attestationType;
    readonly expectedArtifactsLabels = expectedArtifactLabels.PRIVACY;

    attestationArtifact = null;
    attestationValue = null;
}

export class CyberInsuranceRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.CYBERSECURITY_INSURANCE;
    readonly title = 'Do you have cybersecurity insurance?';
    readonly fileArtifactType = FileArtifactType.CYBER_INSURANCE;
    readonly artifactThirdPartyProvidedAuditType = AuditReportTypeName[AuditReportTypeCode.CYBERINSURANCEPOLICY];
    readonly id = this.attestationType;
    readonly expectedArtifactsLabels = expectedArtifactLabels.CYBER_INSURANCE;

    attestationArtifact = null;
    attestationValue = null;
}

export class ExpiredDocumentationRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.UPDATED_ARTIFACTS;
    readonly title: string;
    readonly subtitle: string;
    readonly fileArtifactType = FileArtifactType.AUDIT_REPORT;
    readonly artifactThirdPartyProvidedAuditType = null;
    readonly id = generateExpiredDocumentationRecommendationId(this.artifactId);
    readonly expectedArtifactsLabels = [];

    attestationArtifact = null;
    attestationValue = null;
    auditType = this.expiredAuditType;

    constructor(
        public readonly artifactId: number,
        public readonly artifactName: string,
        public readonly expiredAuditType: AuditReportTypeCode,
    ) {
        this.title = `The ${AuditReportTypeName[this.expiredAuditType]} you uploaded is expired. Do you have a more recent one?`;
        this.subtitle = `You uploaded <strong>${artifactName}</strong>`;
    }
}

export class SiblingDocumentationRecommendation implements ArtifactUploadRecommendation {
    readonly attestationType = AttestationType.SIBLING_DOCS;
    readonly title: string;
    readonly subtitle: string;
    readonly fileArtifactType = FileArtifactType.AUDIT_REPORT;
    readonly artifactThirdPartyProvidedAuditType = null;
    readonly id = generateSiblingDocumentationRecommendationId(this.artifactId);
    readonly expectedArtifactsLabels = [];

    attestationArtifact = null;
    attestationValue = null;
    auditType = this.uploadedAuditType;

    constructor(
        public readonly artifactId: number,
        public readonly artifactName: string,
        public readonly uploadedAuditType: AuditReportTypeCode,
        public readonly requiredSiblingAuditType: AuditReportTypeCode,
    ) {
        this.title = `You\'ve uploaded ${AuditReportTypeName[this.uploadedAuditType]}. Do you have the ${AuditReportTypeName[this.requiredSiblingAuditType]}?`;
        this.subtitle = `We require both to validate control domains.<br/><br/>You uploaded <strong>${artifactName}</strong>`;
    }
}

export function isThirdPartyAuditUploadRecommendation(
    value: ArtifactUploadRecommendation,
): value is ThirdPartyAuditRecommendation {
    return value.attestationType === AttestationType.THIRD_PARTY_AUDITS;
}

export function isExpiredDocumentationRecommendation(
    value: ArtifactUploadRecommendation,
): value is ExpiredDocumentationRecommendation {
    return value.attestationType === AttestationType.UPDATED_ARTIFACTS;
}

export function isSiblingDocumentationRecommendation(
    value: ArtifactUploadRecommendation,
): value is SiblingDocumentationRecommendation {
    return value.attestationType === AttestationType.SIBLING_DOCS;
}

export function isThirdPartyPenTestRecommendation(
    value: ArtifactUploadRecommendation,
): value is ThirdPartyPenTestRecommendation {
    return value.attestationType === AttestationType.PEN_TESTS;
}

export function isPrivacyRecommendation(value: ArtifactUploadRecommendation): value is PrivacyRecommendation {
    return value.attestationType === AttestationType.PRIVACY_DOCS;
}

export function isCyberInsuranceRecommendation(
    value: ArtifactUploadRecommendation,
): value is CyberInsuranceRecommendation {
    return value.attestationType === AttestationType.CYBERSECURITY_INSURANCE;
}
