import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Relationship, RequestStatus } from '../../../entities/relationship';
import { LicenseType, VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { AssessmentStatus } from '../../../entities/assessment';
import { MarkActions } from '../redux/actions/mark.actions';

@Component({
    selector: 'app-head-button-group',
    templateUrl: './head-button-group.component.html',
    styleUrls: ['./head-button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadButtonGroupComponent {
    @Input()
    relationship: Relationship;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input()
    relationshipStatus: RequestStatus;

    @Input()
    latestAssessmentStatus: AssessmentStatus;

    @Input()
    onPrint: boolean;

    @Input()
    isUpForRecertification: boolean;

    @Input()
    hasOrgUserOnboardEnabled: boolean;

    @Input()
    currentUser: VisoUser;

    @Output()
    onboard = new EventEmitter<void>();

    @Output()
    archive = new EventEmitter<void>();

    @Output()
    markAsNotOnboarded = new EventEmitter<void>();

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    confirmRecertifyAssessment = new EventEmitter<void>();

    @Output()
    downloadPDF = new EventEmitter<void>();

    @Output()
    openCreateRiskAdvisory = new EventEmitter<void>();

    get isAssessmentInProgress(): boolean {
        return !!(
            this.latestAssessmentStatus &&
            [
                AssessmentStatus.STARTED,
                AssessmentStatus.COLLECTING_INFORMATION,
                AssessmentStatus.REVIEW_STARTED,
            ].includes(this.latestAssessmentStatus)
        );
    }

    get isRelationshipArchived() {
        return this.relationshipStatus === RequestStatus.ARCHIVED;
    }

    get showStartAssessmentButton(): boolean {
        return (
            !this.isRelationshipArchived &&
            !this.isUpForRecertification &&
            !this.isAssessmentInProgress &&
            !this.isCurrentUserSupport
        );
    }

    get showRecertifyButton(): boolean {
        return (
            !this.isRelationshipArchived &&
            this.isUpForRecertification &&
            !this.isAssessmentInProgress &&
            !this.isCurrentUserSupport
        );
    }

    get showCreateRiskAdvisoryButton(): boolean {
        return !this.relationship?.vendorManagedById;
    }

    get doesCurrentUserHaveTrialLicense() {
        const currentUserLicenseType = this.currentUser?.clientLicense?.licenseType;
        return currentUserLicenseType == LicenseType.TRIAL;
    }

    get doesCurrentUserHaveProdTrialLicense() {
        const currentUserLicenseType = this.currentUser?.clientLicense?.licenseType;
        return currentUserLicenseType == LicenseType.PROD_TRIAL;
    }

    get showOnboardOffboardButton(): boolean {
        return (
            this.currentUser?.authorities.includes(VisoUserRole.OrgAdmin) ||
            this.isCurrentUserSupport ||
            (this.currentUser?.authorities.includes(VisoUserRole.OrgUser) && this.hasOrgUserOnboardEnabled)
        );
    }

    get isCurrentUserSupport(): boolean {
        return this.currentUser?.authorities.includes(VisoUserRole.Support);
    }

    RequestStatus = RequestStatus;
    MarkActions = MarkActions;
    LicenseType = LicenseType;
    Roles = VisoUserRole;
}
