import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';
import { ApolloError } from '@apollo/client';
import { EMPTY, merge, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { NotificationService, NotificationStatus } from '@entities/notification';
import { assessmentCollectionRoute } from '../../routes/assessment-collection/assessment-collection-common/route-constants';
import {
    getNewNotificationsSubscription,
    getNewNotificationsSubscriptionFailed,
    getNewNotificationsSubscriptionSuccess,
    markAllNotificationsAsDismissed,
    markAllNotificationsAsRead,
    markNotificationAsDismissed,
    notificationsQuerySuccess,
    notificationsStatusUpdatedSuccess,
    refreshNotifications,
    setLayout,
    startNotificationPollingFallback,
} from './layout.actions';
import { LayoutType } from '@shared/model/layout-type';

const assessmentArtifactUpdateUrl = '/assessment-artifact-update';
const assessmentRecertificationUrl = '/assessment-recertification';
const userRegistrationUrl = '/sign-up';
const activateAccountUrl = '/activate';

@Injectable()
export class LayoutEffects {
    startNotificationPollingFallback$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startNotificationPollingFallback),
            switchMap(() => merge(timer(0, 10000)).pipe(switchMap(() => of(refreshNotifications())))),
        ),
    );

    refreshNotifications$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshNotifications),
            switchMap(() =>
                this._notificationsService.findAllReadAndNewNotificationsForCurrentUser().pipe(
                    map((response) => {
                        return notificationsQuerySuccess({
                            results: response.edges.map((edge) => edge.node),
                            totalCount: response.totalCount,
                        });
                    }),
                    catchError((error: HttpErrorResponse) => EMPTY),
                ),
            ),
        ),
    );

    getNewNotificationsSubscription$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getNewNotificationsSubscription),
            switchMap(() =>
                this._notificationsService.onNewNotificationForCurrentUser().pipe(
                    map((response) =>
                        getNewNotificationsSubscriptionSuccess({
                            newNotification: response,
                        }),
                    ),
                    catchError((error: ApolloError) => {
                        return of(getNewNotificationsSubscriptionFailed({ error }));
                    }),
                ),
            ),
        ),
    );

    getNewNotificationsSubscriptionFailed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getNewNotificationsSubscriptionFailed),
            map(() => startNotificationPollingFallback()),
        ),
    );

    markAllNotificationsAsRead$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markAllNotificationsAsRead),
            switchMap(({ notificationIds }) =>
                this._notificationsService
                    .updateStatus(NotificationStatus.READ, {
                        recordIds: notificationIds,
                    })
                    .pipe(
                        map(() => notificationsStatusUpdatedSuccess()),
                        catchError((error: HttpErrorResponse) => EMPTY),
                    ),
            ),
        ),
    );

    markNotificationAsDismissed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markNotificationAsDismissed),
            map((action) => action.notifications),
            filter((notifications) => notifications?.length > 0),
            switchMap((notifications) =>
                this._notificationsService
                    .updateStatus(NotificationStatus.DISMISSED, {
                        recordIds: notifications.map((n) => n.id),
                    })
                    .pipe(
                        map(() => notificationsStatusUpdatedSuccess()),
                        catchError((error: HttpErrorResponse) => EMPTY),
                    ),
            ),
        ),
    );

    markAllNotificationsAsDismissed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markAllNotificationsAsDismissed),
            switchMap(() =>
                this._notificationsService.updateStatus(NotificationStatus.DISMISSED, { recordIds: [] }, true).pipe(
                    map(() => notificationsStatusUpdatedSuccess()),
                    catchError((error: HttpErrorResponse) => EMPTY),
                ),
            ),
        ),
    );

    setLayoutType$ = createEffect(() =>
        this._router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            map((event: NavigationStart) => {
                if (
                    event.url.includes(`/${assessmentCollectionRoute}`) ||
                    event.url.includes(assessmentArtifactUpdateUrl) ||
                    event.url.includes(assessmentRecertificationUrl)
                ) {
                    return setLayout({ layout: LayoutType.Assessment });
                }
                if (event.url.includes(userRegistrationUrl) || event.url.includes(activateAccountUrl)) {
                    return setLayout({ layout: LayoutType.Blank });
                }
                return setLayout({ layout: LayoutType.Full });
            }),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _notificationsService: NotificationService,
        private _router: Router,
    ) {}
}
