import { NgModule } from '@angular/core';
import { VisoSnackbarComponent } from './snackbar.component';
import { SafeHtmlPipeModule } from '../../safe-html-pipe/safe-html-pipe.module';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [VisoSnackbarComponent],
    imports: [SafeHtmlPipeModule, CommonModule, MatSnackBarModule, MatButtonModule],
    exports: [VisoSnackbarComponent],
})
export class SnackbarModule {}
