import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttestationsComponent } from './attestations.component';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { attestationRoutes } from './attestations.route';
import { DynamicContentModule } from '@shared/dynamic-content/dynamic-content.module';
import { BadgeModule } from '@shared/badge';
import { CallbackPipeModule } from '@shared/callback-pipe';

const ROUTES = [...attestationRoutes];

@NgModule({
    declarations: [AttestationsComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(ROUTES),
        MatExpansionModule,
        MatTabsModule,
        MatSidenavModule,
        MatListModule,
        DynamicContentModule,
        BadgeModule,
        CallbackPipeModule,
    ],
})
export class AttestationsModule {}
