<div class="card border mb-0">
    <div class="card-body">
        <div class="row">
            <div class="col-auto favicon-container">
                <img [src]="vendorFavicon" [alt]="vendorFaviconAlt" class="favicon img-fluid" />
            </div>
            <div class="col-3">
                <h4 class="mb-1" [ngClass]="{ 'align-margin': !vendorHomepage && !vendorAddress }" data-pw="vendorName">
                    {{ vendorName }}
                </h4>
                <a [externalLink]="vendorHomepage" *ngIf="!!vendorHomepage" class="text-bold d-block mb-0 homepage">{{
                    vendorHomepage | callback: prettifyUrl
                }}</a>
                <p *ngIf="!!vendorAddress" class="d-block text-muted mb-1">{{ vendorAddress }}</p>
            </div>
            <div class="col">
                <p>{{ vendorDescription }}</p>
                <div class="d-flex flex-wrap tag-container">
                    <span [tagColor]="TagColor" *ngFor="let industry of vendorIndustries">{{ industry }}</span>
                </div>
            </div>
            <div class="col-auto actions-container">
                <a
                    *ngIf="!!existingRelationshipCount"
                    class="d-block mb-3"
                    [routerLink]="['/relationships']"
                    [queryParams]="{ search: 'drStatus:[NOT_ONBOARDED, ONBOARDED], vendorOrgId:' + vendorId }"
                >
                    {{ existingRelationshipCount }} Existing Relationship{{ existingRelationshipCount > 1 ? 's' : '' }}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col mt-4">
                <button class="me-2" type="button" mat-button visoButton="primary" (click)="viewProfile.emit(vendor)">
                    View profile
                </button>
                <ng-container *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support]">
                    <button
                        *disableAddRelationshipOnRules
                        type="button"
                        mat-button
                        visoButton="secondary"
                        (click)="addRelationship.emit(vendor)"
                    >
                        Add relationship
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
