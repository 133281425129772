<div class="row mb-3">
    <div class="col-12">
        <p>
            Each domain has an average assurance level based on the type of artifacts reviewed. Advanced
            <app-assurance-level-meter
                class="d-inline-block mx-2"
                [hideTooltip]="true"
                [hideLabel]="true"
                [assuranceLevel]="AssuranceLevels.ADVANCED"
            ></app-assurance-level-meter>
            is the highest level of assurance possible.
        </p>
    </div>

    <div class="col-auto status-box-container pe-0" *ngIf="!hideStatusBox">
        <mat-card [appearance]="'outlined'" class="border rounded-0 stat-card">
            <mat-card-content>
                <strong class="d-block rdp-subtitle mb-1">{{ isStatusRiskBased ? 'Rating' : 'Status' }} </strong>
                <ng-content *ngTemplateOutlet="statusTemplate$ | async"></ng-content>
            </mat-card-content>
            <ng-template #riskBasedStatus>
                <app-mat-risk-chip [disableRipple]="true" [risk]="latestRiskLevel" />
            </ng-template>
            <ng-template #compliantBasedStatus>
                <app-chip *ngIf="isCompliantStatus$ | async; else noInformation" type="success">Compliant</app-chip>
                <ng-template #noInformation>
                    <app-chip [disableRipple]="true" type="danger">Non-Compliant</app-chip>
                </ng-template>
            </ng-template>
            <ng-template #informationBasedStatus>
                <app-chip [disableRipple]="true" *ngIf="isInformationStatus$ | async; else noInformation" type="success"
                    >Collected</app-chip
                >
                <ng-template #noInformation>
                    <app-chip [disableRipple]="true" type="danger">No Information</app-chip>
                </ng-template>
            </ng-template>
            <ng-template #outOfScopeStatus>
                <app-chip [disableRipple]="true" type="basic">Out of Scope</app-chip>
            </ng-template>
            <ng-template #notEnabledStatus>
                <app-chip [disableRipple]="true" type="basic">N/A</app-chip>
            </ng-template>
        </mat-card>
    </div>
    <div class="col">
        <mat-card [appearance]="'outlined'" class="border rounded-0 stat-card">
            <mat-card-content
                *ngIf="{
                    notAssessed: noAssessment$ | async,
                    latestAssessmentCompleted: latestAssessmentCompleted$ | async,
                    controlsPresentCount: controlsPresentCount$ | async,
                    controlsInsufficientCount: controlsInsufficientCount$ | async,
                    controlsNoInformationCount: controlsNoInformationCount$ | async,
                    controlsNotApplicableCount: controlsNotApplicableCount$ | async,
                    controlsWithExceptionsCount: controlsWithExceptionsCount$ | async,
                    controlsWithSubservicerCount: controlsWithSubservicerCount$ | async,
                    controlsWithSharedResponsibilityCount: controlsWithSharedResponsibilityCount$ | async,
                    controlsWithCuecCount: controlsWithCuecCount$ | async,
                } as context"
            >
                <div class="row">
                    <div class="col-auto">
                        <strong class="d-block rdp-subtitle">
                            {{ isRelationshipNoContext ? 'Total' : 'Domains In Scope' }}
                        </strong>
                        <div class="control-counter">
                            {{
                                isRelationshipNoContext
                                    ? (totalControlsCount$ | async)
                                    : (controlsInScopeCount$ | async)
                            }}
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="vr"></div>
                    </div>
                    <div class="col" *ngIf="!context.notAssessed">
                        <div class="row">
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(context.controlsPresentCount || context.latestAssessmentCompleted),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    Present
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{ context.controlsPresentCount || (context.latestAssessmentCompleted ? 0 : '-') }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsInsufficientCount || context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    Insufficient
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsInsufficientCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsNoInformationCount || context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    No Info
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsNoInformationCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsNotApplicableCount || context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    N/A
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsNotApplicableCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto" *ngIf="!context.notAssessed">
                        <div class="vr"></div>
                    </div>
                    <div class="col" *ngIf="!context.notAssessed">
                        <div class="row">
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsWithExceptionsCount || context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    Exceptions
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsWithExceptionsCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsWithSubservicerCount || context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    Subservicer
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsWithSubservicerCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(
                                        context.controlsWithSharedResponsibilityCount ||
                                        context.latestAssessmentCompleted
                                    ),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    Shared Model
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{
                                        context.controlsWithSharedResponsibilityCount ||
                                            (context.latestAssessmentCompleted ? 0 : '-')
                                    }}
                                </div>
                            </div>
                            <div
                                class="col"
                                *ngIf="{
                                    isInactive: !(context.controlsWithCuecCount || context.latestAssessmentCompleted),
                                } as ctx"
                            >
                                <strong class="d-block rdp-subtitle" [ngClass]="{ inactive: ctx.isInactive }">
                                    CUEC
                                </strong>
                                <div class="control-counter" [ngClass]="{ inactive: ctx.isInactive }">
                                    {{ context.controlsWithCuecCount || (context.latestAssessmentCompleted ? 0 : '-') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div
    class="row mb-3"
    *ngFor="let group of groupedSecurityControlDomain$ | async; trackBy: trackGroupedSecurityControlDomain"
>
    <div class="col">
        <app-control-group
            [group]="group"
            [noAssessment]="noAssessment$ | async"
            [latestAssessmentInProgress]="latestAssessmentInProgress$ | async"
            [latestAssessmentCompleted]="latestAssessmentCompleted$ | async"
            [latestAssessmentFollowUpQuestionnaireCreatedDate]="
                latestAssessmentFollowUpQuestionnaireCreatedDate$ | async
            "
            [missingInformationWasRequested]="missingInformationWasRequested$ | async"
            [primaryVendorContact]="primaryVendorContact$ | async"
            [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly$ | async"
            [isDisabled]="group.controlValidationStatus === SecurityControlDomainGroupStatus.DISABLED"
            [isOutOfScope]="group.controlValidationStatus === SecurityControlDomainGroupStatus.OUT_OF_SCOPE"
            [onExport]="onExport"
            (accordionToggled)="accordionToggled($event)"
            (requestInformation)="requestInformation()"
            [frameworkType]="selectedFramework"
        />
    </div>
</div>
