<div class="branding">
    <div class="alert row">
        <div class="col-auto p-0">
            <mat-icon>info_outline</mat-icon>
        </div>
        <div class="col-auto">
            <p><strong>You've been selected to try a new feature! 🎉</strong></p>
            Now you can update the brand color, logo, and icon in the assessment and email experiences. Try it out
            <strong>until October 31, 2024</strong>. For more information,
            <a class="viso-link" [externalLink]="TALK_TO_SALES_URL">talk to sales</a>.
        </div>
    </div>

    <div class="row pt-3">
        <strong class="mb-2"
            >Update your organization's trust profile. Brand settings update colors across the experience and
            communications.</strong
        >

        <div class="col-sm-4">
            <div class="row mt-2">
                <div class="col-sm-5">
                    <h3 class="mb-0 text-nowrap my-auto">Brand color</h3>
                </div>
                <div
                    class="col-sm-7 justify-content-end d-inline-flex"
                    [style.visibility]="color ? 'visible' : 'hidden'"
                >
                    <button mat-button color="warn" (click)="removeBrandColor()">
                        <mat-icon>delete_outline</mat-icon>Remove brand color
                    </button>
                </div>

                <div class="row">
                    <span class="text-light mb-2">
                        Pick a color from your organization's branding to show in communications and collection
                        efforts.</span
                    >

                    <strong [hidden]="color">Your organization has not picked a color yet.</strong>
                    <div class="col">
                        <button
                            mat-stroked-button
                            color="secondary"
                            class="py-4 mt-2"
                            ngx-colors-trigger
                            [(ngModel)]="color"
                        >
                            <div class="d-flex">
                                <ngx-colors colorPickerControls="no-alpha"></ngx-colors>
                                <span class="mx-2" style="line-height: 2rem">
                                    <ng-container *ngIf="color; else boilerplate">{{ color }}</ng-container>
                                    <ng-template #boilerplate>Choose color</ng-template>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 justify-content-end d-inline-flex">
                    <button
                        mat-flat-button
                        color="secondary"
                        (click)="saveBrandingColor()"
                        toggleDisableOnActions
                        *ngIf="color && (color != org.brandingColor || !org.brandingColor)"
                        [disablingActions]="[BrandingActions.SaveBrandingColorRequest]"
                        [enablingActions]="[
                            BrandingActions.SaveBrandingColorRequestSuccess,
                            BrandingActions.SaveBrandingColorRequestFailure,
                        ]"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="row mt-2">
                <div class="col-sm-5">
                    <h3 class="mb-0 nowrap my-auto">Logo &amp; icon</h3>
                </div>
                <div class="col-auto" [hidden]="!(org.logoUrl || org.faviconUrl)">
                    <button mat-button color="warn" (click)="deleteLogoAndIcon()">
                        <mat-icon>delete_outline</mat-icon>Delete logo &amp; icon
                    </button>
                </div>
            </div>

            <span class="text-light"
                >Vendor communications, artifact collection experiences, and trust profiles display your organization's
                icon and logo.</span
            >

            <div class="row pt-3 align-items-center">
                <div class="col-md-auto logo-specs">
                    <div class="text-light">Icon ideal dimensions</div>
                    32 x 32px
                    <div class="text-light pt-2">File type</div>
                    .png, .jpg
                    <div class="text-light pt-2">Max upload size</div>
                    100kb
                </div>
                <div class="d-flex justify-content-center align-items-center image-upload-area icon-logo-upload-area">
                    <img
                        class="organization-icon"
                        *ngIf="org.faviconUrl && !iconLogoIsUploading"
                        [alt]="org.name + ' Company Icon'"
                        [src]="org.faviconUrl"
                    />
                    <ng-container>
                        <button
                            *ngIf="!iconLogoIsUploading"
                            mat-icon-button
                            class="icon-button btn-lg file-picker"
                            [ngClass]="{ 'd-none': org.faviconUrl }"
                            (click)="uploader.click()"
                        >
                            <mat-icon color="link">upload_file</mat-icon>
                        </button>

                        <input hidden type="file" #uploader (change)="uploadFile($event, LogoTypes.IconLogo)" />
                    </ng-container>

                    <div *ngIf="iconLogoIsUploading" class="d-flex flex-column">
                        <div class="flex-shrink-0 flex-grow-1">
                            <div class="mb-2 file-name text-center text-truncate">
                                {{ iconLogoUploadedFileName }}
                            </div>

                            <div class="p-0">
                                <progressbar
                                    class="progress-bar-primary progress-bar-resize custom-progress"
                                    [value]="iconLogoPercentUploaded"
                                ></progressbar>
                            </div>
                        </div>
                        <div class="text-center">
                            <a mat-button class="btn-xs" (click)="cancelIconLogoUpload()"> Cancel Upload </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-auto logo-specs">
                    <div class="text-light">Logo ideal dimensions</div>
                    400 x 150px
                    <div class="text-light pt-2">File type</div>
                    .png, .jpg, .gif
                    <div class="text-light pt-2">Max upload size</div>
                    100kb
                </div>
                <div class="d-flex justify-content-center align-items-center image-upload-area full-logo-upload-area">
                    <img
                        class="organization-logo"
                        *ngIf="org.logoUrl && !fullLogoIsUploading"
                        [alt]="org.name + ' Company Logo'"
                        [src]="org.logoUrl"
                    />
                    <ng-container>
                        <button
                            *ngIf="!fullLogoIsUploading"
                            mat-icon-button
                            class="btn-lg file-picker"
                            [ngClass]="{ 'd-none': org.logoUrl }"
                            (click)="logoUploader.click()"
                        >
                            <mat-icon>upload_file</mat-icon>
                        </button>

                        <input hidden type="file" #logoUploader (change)="uploadFile($event, LogoTypes.FullLogo)" />
                    </ng-container>

                    <div *ngIf="fullLogoIsUploading" class="d-flex flex-column">
                        <div class="flex-shrink-0 flex-grow-1">
                            <div class="mb-2 file-name text-center text-truncate">
                                {{ fullLogoUploadedFileName }}
                            </div>

                            <div class="p-0">
                                <progressbar
                                    class="progress-bar-primary progress-bar-resize custom-progress"
                                    [value]="fullLogoPercentUploaded"
                                ></progressbar>
                            </div>
                        </div>
                        <div class="text-center">
                            <a mat-button class="btn-xs" (click)="cancelFullLogoUpload()"> Cancel Upload </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col preview" style="width: 100%">
            <h3>Preview &amp; save</h3>
            <p>The interface below depicts the collection portal and email template your vendors will encounter.</p>

            <div>
                <mat-button-toggle-group
                    (change)="onPreviewViewChanged($event)"
                    class="preview-toggle custom-button-toggle"
                >
                    <mat-button-toggle checked="true" [value]="PreviewView.ASSESSMENT"
                        >Vendor collection</mat-button-toggle
                    >
                    <mat-button-toggle [value]="PreviewView.EMAIL">Email</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="no-branding my-5 text-center" *ngIf="!customized; else customization">
                <h4 class="my-3">No customizations applied</h4>
                Update brand colors and/or logo to preview customized branding
            </div>

            <ng-template #customization>
                <div
                    class="assessment-preview-container mt-4 preview-content"
                    *ngIf="previewView == PreviewView.ASSESSMENT"
                >
                    <app-assessment-header />
                    <app-assessment-collection></app-assessment-collection>
                </div>

                <iframe
                    class="preview-content"
                    *ngIf="previewView == PreviewView.EMAIL"
                    #iframe
                    style="width: 100%; padding: 4em; padding-left: 40px; height: 100vh; overflow: scroll"
                    [src]="
                        '/api/orgs/example-email-template?brandingColor=' + encodeURIComponent(color || '') | safeUrl
                    "
                ></iframe>
            </ng-template>
        </div>
    </div>
</div>
