import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LayoutType } from '../../shared/model/layout-type';
import { AppState } from '../../shared/redux/state';
import { LayoutState, layoutStateKey } from './layout-state';

export const getLayoutState: MemoizedSelector<AppState, LayoutState> =
    createFeatureSelector<LayoutState>(layoutStateKey);

export const getLayoutType: MemoizedSelector<AppState, LayoutType> = createSelector(
    getLayoutState,
    (state) => state.layout,
);

export const getAssessmentHeaderColor: MemoizedSelector<AppState, string> = createSelector(
    getLayoutState,
    (state) => state.assessmentHeaderColor,
);

export const getAssessmentLogoVisible: MemoizedSelector<AppState, boolean> = createSelector(
    getLayoutState,
    (state) => state.assessmentLogoVisible,
);
