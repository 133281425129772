import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { DatatypesComponent } from './datatypes.component';
import { VisoUserRole } from '../../entities/viso-user';

export const datatypesRoute: Routes = [
    {
        path: 'data-types',
        component: DatatypesComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
            pageTitle: 'Data Types',
        },
        canActivate: [AuthGuard],
    },
];
