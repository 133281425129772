import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { SettingsService } from './core/settings/settings.service';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from './shared/redux/state';
import { setViewport } from './shared/redux/actions';
import { LayoutType } from './shared/model/layout-type';
import { getLayoutType } from './layout/redux/layout.selectors';
import { RouterUtilsService } from './shared/utils/router-utils.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LogrocketService } from './shared/services/logrocket.service';
import { GoogleTagInScriptInjectorService } from './routes/user-registration/google-tag-script-injector.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [GoogleTagInScriptInjectorService],
})
export class AppComponent implements OnInit, OnDestroy {
    layout$: Observable<LayoutType>;

    LayoutType = LayoutType;

    private _unsub: Subject<void> = new Subject<void>();

    @HostBinding('class.layout-fixed') get isFixed() {
        return this.settings.layout.isFixed;
    }
    @HostBinding('class.aside-collapsed') get isCollapsed() {
        return this.settings.layout.isCollapsed;
    }
    @HostBinding('class.layout-boxed') get isBoxed() {
        return this.settings.layout.isBoxed;
    }
    @HostBinding('class.layout-fs') get useFullLayout() {
        return this.settings.layout.useFullLayout;
    }
    @HostBinding('class.hidden-footer') get hiddenFooter() {
        return this.settings.layout.hiddenFooter;
    }
    @HostBinding('class.layout-h') get horizontal() {
        return this.settings.layout.horizontal;
    }
    @HostBinding('class.aside-float') get isFloat() {
        return this.settings.layout.isFloat;
    }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() {
        return this.settings.layout.offsidebarOpen;
    }
    @HostBinding('class.aside-toggled') get asideToggled() {
        return this.settings.layout.asideToggled;
    }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    constructor(
        public settings: SettingsService,
        private _store$: Store<AppState>,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        // Things below this comment aren't used in this component,
        // but are included here to instantiate them at app start.
        // @ts-ignore
        private _routerUtils: RouterUtilsService,
        // @ts-ignore
        private _LogRocket: LogrocketService,
        private _googleTagInjectorService: GoogleTagInScriptInjectorService,
    ) {}

    ngOnInit(): void {
        fromEvent(window, 'resize')
            .pipe(
                debounceTime(200),
                map(() => window.innerWidth),
                distinctUntilChanged(),
                startWith(window.innerWidth),
                tap((width) => this._store$.dispatch(setViewport({ width }))),
                takeUntil(this._unsub),
            )
            .subscribe();

        this.layout$ = this._store$.pipe(select(getLayoutType));

        this.matIconRegistry.addSvgIcon(
            'user_circle_filled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../content/svg/user.svg'),
        );

        if (environment.production) {
            this._googleTagInjectorService.injectScript();
        }
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
