import { NgModule } from '@angular/core';

import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { BasicHeaderComponent } from './basic-header/basic-header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationComponent } from './header/notification/notification.component';
import { AccountService } from '../shared';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './redux/layout.effects';
import { StoreModule } from '@ngrx/store';
import { layoutStateKey } from './redux/layout-state';
import { layoutReducer } from './redux/layout.reducer';
import { RouterModule } from '@angular/router';
import { EventNotificationComponent } from './header/notification/event-notification/event-notification.component';
import { TipNotificationComponent } from './header/notification/tip-notification/tip-notification.component';
import { LinkNotificationComponent } from './header/notification/link-notification/link-notification.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssessmentHeaderComponent } from './assessment-header/assessment-header.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        MatButtonModule,
        // Redux
        StoreModule.forFeature(layoutStateKey, layoutReducer),
        EffectsModule.forFeature([LayoutEffects]),
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    providers: [AccountService],
    declarations: [
        SidebarComponent,
        BasicHeaderComponent,
        HeaderComponent,
        AssessmentHeaderComponent,
        NavsearchComponent,
        NotificationComponent,
        EventNotificationComponent,
        TipNotificationComponent,
        LinkNotificationComponent,
    ],
    exports: [
        SidebarComponent,
        HeaderComponent,
        BasicHeaderComponent,
        AssessmentHeaderComponent,
        NavsearchComponent,
        NotificationComponent,
        LinkNotificationComponent,
    ],
})
export class LayoutModule {}
