<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START Left navbar-->

    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="/">
            <div class="brand-logo">
                <img class="img-fluid basic-logo" src="content/img/viso_navbar_brand.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="content/img/viso_navbar_brand.png" alt="App Logo" />
            </div>
        </a>
        <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
        <a
            class="collapse-button nav-link"
            trigger-resize=""
            (click)="toggleCollapsedSidebar()"
            *ngIf="!isCollapsedText()"
        >
            <mat-icon>menu_open</mat-icon>
        </a>
        <a
            class="expand-button nav-link"
            trigger-resize=""
            (click)="toggleCollapsedSidebar()"
            *ngIf="!isCollapsedText()"
        >
            <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
        </a>
        <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
        <a
            class="nav-link sidebar-toggle d-md-none"
            (click)="settings.layout.asideToggled = !settings.layout.asideToggled; $event.stopPropagation()"
        >
            <i class="fas fa-bars"></i>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- END Left navbar-->

    <div class="navbar-content-wrapper">
        <div class="breadcrumbs-wrapper">
            <ng-container [dynamicContainer]="breadcrumbsContainerToken"></ng-container>
        </div>
        <ul class="navbar-nav me-auto flex-row">
            <li *ngIf="isOrgDomainOverridden" class="nav-item">
                <a class="nav-link">
                    <button mat-button>
                        <!-- TODO: button -->
                        {{ this.account.orgName }} : {{ this.account.orgDomains.join(', ') }}
                    </button>
                </a>
            </li>
            <!-- END lock screen-->
        </ul>

        <!-- START Right Navbar-->
        <ul class="navbar-nav flex-row">
            <app-navsearch *ngIf="isNotAdminRole"></app-navsearch>

            <li
                class="nav-item dropdown dropdown-list"
                dropdown
                *ngIf="account"
                appNotificationMenu
                [currentAccount]="account"
                (isOpenChange)="notificationsDropdownChange($event)"
            ></li>

            <li class="nav-item dropdown dropdown-list" dropdown *ngIf="account">
                <a
                    class="nav-link dropdown-toggle dropdown-toggle-nocaret text-primary"
                    dropdownToggle
                    data-pw="userActions"
                >
                    <mat-icon svgIcon="user_circle_filled"></mat-icon>
                </a>
                <!-- START Dropdown menu-->
                <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
                    <div class="dropdown-item list-group-item">
                        <a class="icon-item p-2 d-flex align-items-center" [routerLink]="['/user-profile', account.id]">
                            <mat-icon fontSet="material-icons-outlined" class="me-2">manage_accounts</mat-icon>
                            <span>Profile</span>
                        </a>
                    </div>
                    <div class="dropdown-item list-group-item">
                        <a
                            class="icon-item p-2 d-flex align-items-center"
                            title="Logout"
                            (click)="logout()"
                            data-pw="logoutAction"
                        >
                            <mat-icon fontSet="material-icons-outlined" class="me-2">logout</mat-icon>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>
                <!-- END Dropdown menu-->
            </li>

            <!-- START Offsidebar button-->
            <!--<li class="nav-item">-->
            <!--<a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">-->
            <!--<em class="icon-notebook"></em>-->
            <!--</a>-->
            <!--</li>-->
            <!-- END Offsidebar menu-->
        </ul>
    </div>
    <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
