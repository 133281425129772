import { Injectable } from '@angular/core';
import { FileArtifactType } from '@entities/artifact';
import { isCertOnlyArtifactRecommendation, isExpiredArtifactRecommendation } from '@entities/recommendation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NOOP } from '@shared/redux/actions';
import { combineLatest, filter, first, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
    ArtifactUploadRecommendation,
    CyberInsuranceRecommendation,
    ExpiredDocumentationRecommendation,
    PrivacyRecommendation,
    PublicAssessmentRTPFileArtifact,
    SiblingDocumentationRecommendation,
    ThirdPartyAuditRecommendation,
    ThirdPartyPenTestRecommendation,
    generateExpiredDocumentationRecommendationId,
    generateSiblingDocumentationRecommendationId,
    isExpiredDocumentationRecommendation,
    isSiblingDocumentationRecommendation,
} from '../../models';
import {
    addArtifactUploadRecommendation,
    onRtpFileArtifactCreation,
    refreshScopeRelatedArtifactUploadRecommendations,
    refreshSiblingOrExpiredArtifactUploadRecommendations,
    removeArtifactRequestSuccess,
    removeArtifactUploadRecommendation,
    setArtifactUploadRecommendation,
    setArtifactUploadRecommendations,
    uploadFiles,
    uploadFilesRequestSuccess,
} from '../actions';
import {
    getArtifactUploadRecommendations,
    getAssessmentRecommendations,
    getExpiredDocumentationRecommendations,
    getIsCyberInsuranceInScope,
    getIsPenTestInScope,
    getIsPrivacyInScope,
    getSortedRtpFileArtifacts,
    getSiblingDocumentationRecommendations,
} from '../selectors';

@Injectable()
export class ArtifactUploadRecommendationEffects {
    refreshRecommendationsForInScopeDimensionsForNonArtifactUpdateAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshScopeRelatedArtifactUploadRecommendations),
            withLatestFrom(
                this._store$.select(getSortedRtpFileArtifacts),
                this._store$.select(getArtifactUploadRecommendations),
            ),
            switchMap(([, rtpFileArtifacts, existingRecommendations]) =>
                combineLatest([
                    this._store$.select(getIsPenTestInScope),
                    this._store$.select(getIsPrivacyInScope),
                    this._store$.select(getIsCyberInsuranceInScope),
                ]).pipe(
                    map(([isPenTestInScope, isPrivacyInScope, isCyberInsuranceInScope]) => {
                        // Regenerate scope-related recommendations, being sure to keep any the user has saved attestations to, so we don't overwrite their changes.
                        const recommendationsToCreate: ArtifactUploadRecommendation[] = [];

                        const sufficientlyClassifiedArtifacts = rtpFileArtifacts.filter(
                            (artifact) => artifact.requiredRTPValidationsReceived,
                        );

                        // Third party audits (always in scope)
                        const thirdPartyAuditRecommendation = this.tryCreateRecommendation(
                            ThirdPartyAuditRecommendation,
                            FileArtifactType.AUDIT_REPORT,
                            sufficientlyClassifiedArtifacts,
                            existingRecommendations,
                        );
                        if (!!thirdPartyAuditRecommendation) {
                            recommendationsToCreate.push(thirdPartyAuditRecommendation);
                        }

                        // Pen tests
                        if (isPenTestInScope) {
                            const penTestRecommendation = this.tryCreateRecommendation(
                                ThirdPartyPenTestRecommendation,
                                FileArtifactType.ASSESSMENT,
                                sufficientlyClassifiedArtifacts,
                                existingRecommendations,
                            );
                            if (!!penTestRecommendation) {
                                recommendationsToCreate.push(penTestRecommendation);
                            }
                        }

                        // Privacy
                        if (isPrivacyInScope) {
                            const privacyRecommendation = this.tryCreateRecommendation(
                                PrivacyRecommendation,
                                FileArtifactType.PRIVACY,
                                sufficientlyClassifiedArtifacts,
                                existingRecommendations,
                            );
                            if (!!privacyRecommendation) {
                                recommendationsToCreate.push(privacyRecommendation);
                            }
                        }

                        // Cyber insurance
                        if (isCyberInsuranceInScope) {
                            const cyberInsuranceRecommendation = this.tryCreateRecommendation(
                                CyberInsuranceRecommendation,
                                FileArtifactType.CYBER_INSURANCE,
                                sufficientlyClassifiedArtifacts,
                                existingRecommendations,
                            );
                            if (!!cyberInsuranceRecommendation) {
                                recommendationsToCreate.push(cyberInsuranceRecommendation);
                            }
                        }

                        return setArtifactUploadRecommendations({ recommendations: recommendationsToCreate });
                    }),
                ),
            ),
        ),
    );

    createExpiredDocumentationRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshSiblingOrExpiredArtifactUploadRecommendations),
            switchMap(() =>
                this._store$.select(getAssessmentRecommendations).pipe(
                    withLatestFrom(
                        this._store$.select(getSortedRtpFileArtifacts),
                        this._store$.select(getExpiredDocumentationRecommendations),
                    ),
                    switchMap(([assessmentRecommendations, rtpFileArtifacts, expiredDocsUploadRecommendations]) => {
                        const expiredDocsAssessmentRecommendation = assessmentRecommendations.find(
                            isExpiredArtifactRecommendation,
                        );

                        if (!expiredDocsAssessmentRecommendation) {
                            return of(NOOP());
                        }

                        return expiredDocsAssessmentRecommendation.artifactIds
                            .map((artifactId) =>
                                rtpFileArtifacts.find(
                                    (artifact) => artifact.allowsRecommendations && artifact.artifact.id === artifactId,
                                ),
                            )
                            .filter(
                                (expiredArtifact) =>
                                    !!expiredArtifact &&
                                    !expiredDocsUploadRecommendations.some(
                                        (rec) =>
                                            rec.id ===
                                            generateExpiredDocumentationRecommendationId(expiredArtifact.artifact.id),
                                    ),
                            )
                            .map((expiredArtifact) =>
                                addArtifactUploadRecommendation({
                                    recommendation: new ExpiredDocumentationRecommendation(
                                        expiredArtifact.artifact.id,
                                        expiredArtifact.artifact.fileName,
                                        expiredArtifact.validation?.auditReportType,
                                    ),
                                }),
                            );
                    }),
                ),
            ),
        ),
    );

    createSiblingDocumentationRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshSiblingOrExpiredArtifactUploadRecommendations),
            withLatestFrom(this._store$.select(getSortedRtpFileArtifacts)),
            switchMap(([, rtpFileArtifacts]) =>
                this._store$.select(getAssessmentRecommendations).pipe(
                    withLatestFrom(this._store$.select(getSiblingDocumentationRecommendations)),
                    switchMap(([assessmentRecommendations, siblingDocsUploadRecommendations]) => {
                        const siblingDocsAssessmentRecommendations = assessmentRecommendations.filter(
                            isCertOnlyArtifactRecommendation,
                        );

                        if (!siblingDocsAssessmentRecommendations.length) {
                            return of(NOOP());
                        }

                        const siblingDocsUploadRecommendationsIds = siblingDocsUploadRecommendations.map(
                            (uploadRecommendation) => uploadRecommendation.id,
                        );

                        return siblingDocsAssessmentRecommendations
                            .filter(
                                (assessmentRecommendation) =>
                                    !siblingDocsUploadRecommendationsIds.includes(
                                        generateSiblingDocumentationRecommendationId(
                                            assessmentRecommendation.artifactIds[0],
                                        ),
                                    ),
                            )
                            .map((assessmentRecommendation) => {
                                const requiringSiblingArtifactId = assessmentRecommendation.artifactIds[0];
                                const requiringSiblingArtifact = rtpFileArtifacts.find(
                                    (artifact) =>
                                        artifact.allowsRecommendations &&
                                        artifact.artifact.id === requiringSiblingArtifactId,
                                );

                                // Skip if the recommendation is about an artifact that's on the assessment but not uploaded as part of the assessment,
                                return !!requiringSiblingArtifact
                                    ? addArtifactUploadRecommendation({
                                          recommendation: new SiblingDocumentationRecommendation(
                                              requiringSiblingArtifactId,
                                              requiringSiblingArtifact.artifact.fileName,
                                              requiringSiblingArtifact.validation?.auditReportType,
                                              assessmentRecommendation.reportAuditReportType,
                                          ),
                                      })
                                    : NOOP();
                            });
                    }),
                ),
            ),
        ),
    );

    removeSiblingOrExpiredArtifactRecommendationForRemovedOrIncorrectlyClassifiedArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifactRequestSuccess),
            withLatestFrom(this._store$.select(getArtifactUploadRecommendations)),
            map(([{ artifactId }, artifactUploadRecommendations]) => {
                const isSiblingOrExpiredRecommendationForArtifact = (rec: ArtifactUploadRecommendation) =>
                    (isExpiredDocumentationRecommendation(rec) || isSiblingDocumentationRecommendation(rec)) &&
                    rec.artifactId === artifactId;
                return artifactUploadRecommendations.filter((artifactUploadRecommendation) =>
                    isSiblingOrExpiredRecommendationForArtifact(artifactUploadRecommendation),
                );
            }),
            mergeMap((siblingOrExpiredRecommendationsForRemovedArtifact) =>
                siblingOrExpiredRecommendationsForRemovedArtifact.map((recommendation) =>
                    removeArtifactUploadRecommendation({ recommendation }),
                ),
            ),
        ),
    );

    associateUploadedArtifactWithRecommendation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFiles),
            filter(({ recommendationId }) => !!recommendationId),
            switchMap(({ recommendationId }) =>
                this._actions$.pipe(
                    ofType(uploadFilesRequestSuccess),
                    first(),
                    filter(({ successfullyUploadedArtifacts }) => !!successfullyUploadedArtifacts?.length),
                    switchMap(({ successfullyUploadedArtifacts }) =>
                        this._actions$.pipe(
                            ofType(onRtpFileArtifactCreation),
                            withLatestFrom(this._store$.select(getArtifactUploadRecommendations)),
                            map(([{ rtpFileArtifacts }, recommendations]) => {
                                const recommendation = recommendations.find((rec) => rec.id === recommendationId);
                                if (!!recommendation) {
                                    const uploadedAsRtpArtifact = rtpFileArtifacts.find(
                                        (a) => a.artifact.id === successfullyUploadedArtifacts[0].id,
                                    );
                                    recommendation.attestationArtifact = uploadedAsRtpArtifact;
                                    return setArtifactUploadRecommendation({ recommendation });
                                }
                                return NOOP();
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    disassociateRemovedArtifactFromRecommendation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifactRequestSuccess),
            withLatestFrom(this._store$.select(getArtifactUploadRecommendations)),
            map(([{ artifactId }, recommendations]) => {
                const recommendation = recommendations.find(
                    (rec) => rec.attestationArtifact?.artifact.id === artifactId,
                );
                if (!!recommendation) {
                    recommendation.attestationArtifact = null;
                    return setArtifactUploadRecommendation({ recommendation });
                }
                return NOOP();
            }),
        ),
    );

    private tryCreateRecommendation<T extends ArtifactUploadRecommendation>(
        recommendationClass: new () => T,
        fileArtifactType: FileArtifactType,
        sufficientlyClassifiedArtifacts: PublicAssessmentRTPFileArtifact[],
        existingRecommendations: ArtifactUploadRecommendation[],
    ): ArtifactUploadRecommendation | null {
        const artifacts = sufficientlyClassifiedArtifacts.filter(
            (artifact) => artifact.fileArtifactType === fileArtifactType,
        );

        const newRecommendation = new recommendationClass();
        const areThereArtifacts = !!artifacts.length;

        if (!areThereArtifacts) {
            const existingRecommendationWithAttestations = existingRecommendations.find(
                (rec) => rec.attestationType === newRecommendation.attestationType,
            );
            return existingRecommendationWithAttestations ?? newRecommendation;
        }
        return null;
    }

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
