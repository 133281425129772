import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTypeConfig } from '../../../admin/client-profile/client-profile.model';
import { ContextType } from '../../../entities/context-type';
import { Relationship, RequestStatus } from '../../../entities/relationship';
import { VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { Tag } from '../../../entities/tag';
import { PrimaryVendorContact } from '../../primary-vendor-contact';

@Component({
    selector: 'app-relationship-tab',
    templateUrl: './relationship-tab.component.html',
    styleUrls: ['./relationship-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipTabComponent {
    @Input()
    currentUser: VisoUser;

    @Input()
    relationship: Relationship;

    @Input()
    dataTypesConfig: DataTypeConfig[];

    @Input()
    businessOwner: VisoUser;

    @Input()
    primaryVendorContact: PrimaryVendorContact;

    @Input()
    latestCompletedAssessmentDate: Date;

    @Input()
    subscribers: VisoUser[];

    @Input()
    export = false;

    @Input()
    tags: Tag[];

    @Input()
    dateOfNextArtifactExpiration: Date;

    @Output()
    unsubscribeSubscriber = new EventEmitter<number>();

    @Output()
    linkTag = new EventEmitter<Tag>();

    @Output()
    unlinkTag = new EventEmitter<Tag>();

    Roles = VisoUserRole;
    RequestStatus = RequestStatus;

    get relationshipId(): number {
        return this.relationship?.id;
    }

    get contextTypes(): ContextType[] {
        return this.relationship?.contextTypes;
    }

    get hasNoContext(): boolean {
        const { contextTypes, dataTypes } = this.relationship;
        return !contextTypes.length && !dataTypes.length;
    }

    get addContextLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/context`,
                },
            },
        ];
    }

    isAuditor = (user: VisoUser) => {
        return user?.authorities?.includes(VisoUserRole.Auditor);
    };
}
