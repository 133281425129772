<div class="row">
    <div class="col">
        <app-header [request]="request" [businessOwner]="businessOwner"></app-header>
        <app-stat-boxes
            [request]="request"
            [latestAssessment]="latestAssessment"
            [latestRiskAssessment]="latestRiskAssessment"
            [latestNonTransitionalRiskAssessment]="latestNonTransitionalRiskAssessment"
            [isUpForRecertification]="isUpForRecertification"
            [isRecertificationOverdue]="isRecertificationOverdue"
            [isAssessmentInProgress]="isAssessmentInProgress"
            [recertificationDueDaysLeftCount]="recertificationDueDaysLeftCount"
        ></app-stat-boxes>
        <div class="card card-default">
            <div class="card-header">
                <p class="lead">Relationship Details</p>
            </div>
            <div class="card-body">
                <app-relationship-tab
                    [relationship]="request"
                    [dataTypesConfig]="dataTypesProfileConfig"
                    [businessOwner]="businessOwner"
                    [primaryVendorContact]="primaryVendorContact"
                    [tags]="tags"
                    [export]="true"
                >
                </app-relationship-tab>
            </div>
        </div>
        <div class="card card-default risk-timeline-card">
            <div class="card-header">
                <p class="lead">Risk Timeline</p>
            </div>
            <div class="card-body">
                <app-risk-timeline-export
                    [riskAssessments]="riskAssessments"
                    [riskAssessmentsDatasets]="riskAssessmentsDatasets"
                >
                </app-risk-timeline-export>
            </div>
        </div>
        <div class="card card-default">
            <div class="card-header">
                <p class="lead">Assessments</p>
            </div>
            <div class="card-body">
                <app-assessment-list-item
                    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig"
                    [assessment]="latestAssessment"
                    [relationship]="request"
                    [currentUser]="currentAccount"
                    [businessOwner]="businessOwner"
                    [defaultExpanded]="true"
                />
                <br />
                <p class="lead">Lifecycle History</p>
                <app-assessment-list-item
                    *ngFor="let pastAssessment of pastAssessments"
                    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig"
                    [assessment]="pastAssessment"
                    [relationship]="request"
                    [currentUser]="currentAccount"
                    [businessOwner]="businessOwner"
                />
            </div>
        </div>
        <div class="card card-default">
            <div class="card-header">
                <p class="lead">Artifacts</p>
            </div>
            <div class="card-body">
                <app-artifact-list-export [artifacts]="artifacts"></app-artifact-list-export>
            </div>
        </div>
        <h4 class="lead">Risk Analysis > Control Domain</h4>
        <ng-container *ngFor="let controlGroup of groupedControlDomains">
            <div *ngIf="!controlGroup.allControlsDisabled">
                <h5 class="text-bold">{{ controlGroup.controlDomainTypeLabel }}</h5>
                <app-security-control-domains
                    class="d-block my-3"
                    [relationship]="request"
                    [securityControlDomain]="securityControlDomain"
                    [controls]="controlGroup.controlDomains"
                    [latestAssessmentStatus]="latestAssessmentStatus"
                    [latestAssessmentFollowUpQuestionnaireCreatedDate]="
                        latestAssessmentFollowUpQuestionnaireCreatedDate
                    "
                    [missingInformationWasRequested]="missingInformationWasRequested"
                    [primaryVendorContact]="primaryVendorContact"
                    [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly"
                    [isStatusRiskBased]="controlGroup.isStatusRiskBased"
                    [isStatusCompliantBased]="controlGroup.isStatusCompliantBased"
                    [isStatusInformationBased]="controlGroup.isStatusInformationBased"
                    [isOutOfScope]="controlGroup.allControlsOutOfScope"
                    [isNotEnabled]="controlGroup.allControlsDisabled"
                    [showOutOfScopeDomains]="true"
                    [artifactSupersession]="artifactSupersession"
                    [onExport]="true"
                />
            </div>
        </ng-container>
        <h4 class="lead">Risk Analysis > Comments</h4>
        <div>
            <app-comment-list
                [comments]="comments"
                [currentAccount]="currentAccount"
                (quillViewsCreated)="quillViewsCreated.emit()"
            ></app-comment-list>
        </div>
        <h4 class="lead">Attachments</h4>
        <div>
            <app-attachment-list-export [attachments]="attachments"></app-attachment-list-export>
        </div>
    </div>
</div>
