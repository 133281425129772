import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../redux/state';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    getServerSessionCompleted,
    getServerSessionRequest,
    loginRequest,
    logoutRequest,
} from '../../routes/session/redux/session.actions';
import {
    getIsAuthenticated,
    getIsSessionLoaded,
    getUserAccount,
    getUserAuthority,
} from '../../routes/session/redux/session.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { VisoUser, VisoUserRole } from '../../entities/viso-user';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorKey } from '../../shared/model/error-keys';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    ErrorKey = ErrorKey;

    constructor(
        private router: Router,
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const routeAuthorities = route.data['authorities'];
        return this.checkLogin(routeAuthorities);
    }

    checkLogin(routeAuthorities: VisoUserRole[]): Observable<boolean> {
        if (!routeAuthorities || routeAuthorities.length === 0) {
            return of(true);
        }

        return this._store$.pipe(
            select(getIsAuthenticated),
            withLatestFrom(
                this._store$.pipe(select(getUserAccount)),
                this._store$.pipe(select(getUserAuthority(routeAuthorities))),
                this._store$.pipe(select(getIsSessionLoaded)),
            ),
            map(([isAuthenticated, account, hasAnyAuthority, isSessionLoaded]) => {
                if (!isAuthenticated && isSessionLoaded) {
                    this._store$.dispatch(getServerSessionRequest());
                }
                return { fromServer: !isAuthenticated, account, hasAnyAuthority };
            }),
            switchMap(({ fromServer, account, hasAnyAuthority }) =>
                fromServer
                    ? this._actions$.pipe(
                          ofType(getServerSessionCompleted),
                          map(({ error }) => this.checkAuthority(account, routeAuthorities, hasAnyAuthority, error)),
                      )
                    : of(this.checkAuthority(account, routeAuthorities, hasAnyAuthority, null)),
            ),
        );
    }

    private checkAuthority(
        account: VisoUser,
        routeAuthorities: string[],
        hasAnyAuthority: boolean,
        error?: HttpErrorResponse,
    ): boolean {
        if (error) {
            if (
                error.error.errorKey === ErrorKey.INVALID_LOGIN ||
                error.error.errorKey === ErrorKey.CANNOT_REGISTER_UNDER_EXISTING_CLIENT
            ) {
                this.navigateToErrorPage(error.error.errorKey);
                return false;
            }
        }
        if (account) {
            if (!account.authorities || account.authorities.length !== 1) {
                this.router.navigate(['accessdenied']);
                this._store$.dispatch(logoutRequest());
                return false;
            }

            if (hasAnyAuthority) {
                return true;
            }

            if (isDevMode()) {
                console.error('User has none of the required authorities for this route: ', routeAuthorities);
            }

            this.router.navigate(['accessdenied']);
            return false;
        }
        console.log('Account not found, redirecting via login service.');
        this._store$.dispatch(loginRequest());
        return false;
    }

    private navigateToErrorPage(errorPageRoute: string) {
        this.router.navigate([errorPageRoute]);
        setTimeout(() => {
            this._store$.dispatch(logoutRequest());
        }, 3000);
    }
}
