<div class="card-body pt-1">
    <ng-container *ngIf="attachments && attachments.length">
        <div class="row" [style.marginTop.px]="20">
            <div class="col">
                <ngx-datatable
                    class="bootstrap"
                    [columnMode]="'force'"
                    [rowHeight]="35"
                    [headerHeight]="30"
                    [rows]="attachments"
                    [scrollbarV]="true"
                    [count]="attachments.length"
                    [sorts]="[{ prop: 'uploadedDate', dir: 'desc' }]"
                    data-pw="attachmentListTable"
                >
                    <ngx-datatable-column
                        [width]="40"
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [resizeable]="false"
                        [frozenLeft]="true"
                    >
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div class="checkbox c-checkbox float-start mt0">
                                <label>
                                    <input
                                        class="me-2"
                                        type="checkbox"
                                        [checked]="allAttachmentsSelected"
                                        (change)="selectAllAttachments()"
                                    />
                                    <span class="fa fa-check"></span>
                                </label>
                            </div>
                        </ng-template>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="checkbox c-checkbox float-start mt0">
                                <label>
                                    <input
                                        class="me-2"
                                        type="checkbox"
                                        [checked]="selectedAttachments.get(row)"
                                        (change)="attachmentSelected(row)"
                                    />
                                    <span class="fa fa-check"></span>
                                </label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="File" prop="fileName"></ngx-datatable-column>

                    <ngx-datatable-column name="Uploaded On" prop="uploadedDate">
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value | date: 'mediumDate' }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        [width]="40"
                        cellClass="order-50 text-center bg-white"
                        headerClass="order-50"
                        [resizeable]="false"
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [frozenRight]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="additional-actions" placement="bottom left" dropdown container="body">
                                <button
                                    id="button-container"
                                    dropdownToggle
                                    type="button"
                                    class="btn-icon float-end"
                                    aria-controls="dropdown-container"
                                >
                                    <em class="fa fa-ellipsis-v"></em>
                                </button>
                                <ul
                                    id="dropdown-container"
                                    *dropdownMenu
                                    class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="button-container"
                                >
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="deleteAttachment.emit(row)">
                                            Delete Attachment
                                        </a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="downloadAttachment.emit(row)">
                                            Download Attachment
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </ng-container>
</div>
