import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState, appStateKey } from './state';

export const getAppState: MemoizedSelector<{}, AppState> = createFeatureSelector<AppState>(appStateKey);

export const isViewportExtraLarge: MemoizedSelector<AppState, boolean> = createSelector(
    getAppState,
    (state) => state.resolution >= 1200,
);

export const isPhoneViewport: MemoizedSelector<AppState, boolean> = createSelector(
    getAppState,
    (state) => state.resolution < 768,
);
