import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/model/controls-of';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { AssessmentSubmissionFormPayload } from '../../../models';

@Component({
    selector: 'app-submission-step',
    templateUrl: './submission-step.component.html',
    styleUrls: ['./submission-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionStepComponent implements OnInit {
    @Input({ required: true })
    submitted: boolean;

    @Input({ required: true })
    supplementalQuestionnaireConfigured: boolean;

    @Input({ required: true })
    clientName: string;

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    submit = new EventEmitter<AssessmentSubmissionFormPayload>();

    submittedAnimationOptions: AnimationOptions = {
        path: 'content/animations/assessment-collection/submitted.json',
        loop: true,
        autoplay: false,
    };

    assessmentSubmissionFormGroup: FormGroup<ControlsOf<AssessmentSubmissionFormPayload>>;
    animation: AnimationItem;

    constructor(
        private _fb: FormBuilder,
        private _formUtilsService: FormUtilsService,
        private _ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.assessmentSubmissionFormGroup = this._fb.group({
            firstName: this._fb.control('', { validators: Validators.required }),
            lastName: this._fb.control('', { validators: Validators.required }),
            email: this._fb.control('', { validators: [Validators.required, Validators.email] }),
        });
    }

    animationCreated(animation: AnimationItem) {
        this.animation = animation;
    }

    emitSubmit(): void {
        const payload = this._formUtilsService.getCleanTypedFormGroupValue(this.assessmentSubmissionFormGroup, true);
        this.submit.emit(payload);
        this.playAnimation();
    }

    playAnimation(): void {
        this._ngZone.runOutsideAngular(() => {
            setTimeout(() => this.animation.play(), 300);
        });
    }
}
