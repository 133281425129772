import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SharedModule } from '../../shared';
import { ControlsComponent } from './controls.component';
import { controlsRoute } from './';
import { ControlDomainService } from '../../entities/control-domain';
import { ControlHelpModalContent } from './controls-help.component';
import { ControlEffects } from './redux/controls.effects';
import { controlsStateKey } from './redux/controls.state';
import { controlsReducer } from './redux/controls.reducer';
import { ControlsListComponent } from './controls-list/controls-list.component';

const ENTITY_STATES = [...controlsRoute];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        NgxSliderModule,
        StoreModule.forFeature(controlsStateKey, controlsReducer),
        EffectsModule.forFeature([ControlEffects]),
    ],
    declarations: [ControlsComponent, ControlHelpModalContent, ControlsListComponent],
    providers: [ControlDomainService],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ControlsModule {}
