import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionnaireArtifact } from '@entities/artifact';
import { QuestionnaireAnswer, QuestionnaireAnswerType, QuestionnaireAnswerTypeLabels } from '@entities/assessment';
import { ControlDomainTypeLabels } from '@entities/control-domain';
import { BehaviorSubject, combineLatest, debounceTime, filter, Subject, takeUntil } from 'rxjs';

type QuestionnaireAnswerFormControls = {
    answerType: FormControl<QuestionnaireAnswerType>;
    answer: FormControl<string>;
};

@Component({
    selector: 'app-answer-questionnaire',
    templateUrl: './answer-questionnaire.component.html',
    styleUrls: ['./answer-questionnaire.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerQuestionnaireComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    set questionnaire(value: QuestionnaireArtifact) {
        this.questionnaire$.next(value);
    }

    @Input({ required: true })
    set followupControlDomainIds(value: number[]) {
        this.followupControlDomainIds$.next(value);
    }

    @Input({ required: true })
    forFollowup: boolean;

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    wentToCollectArtifacts = new EventEmitter<void>();

    @Output()
    questionnaireUpdated = new EventEmitter<QuestionnaireArtifact>();

    questionnaire$ = new BehaviorSubject<QuestionnaireArtifact>(null);
    followupControlDomainIds$ = new BehaviorSubject<number[]>([]);

    questionnaireFormArray: FormArray<FormGroup<QuestionnaireAnswerFormControls>>;
    QuestionnaireAnswerType = QuestionnaireAnswerType;
    QuestionnaireAnswerTypeTabLabels = QuestionnaireAnswerTypeLabels;
    ControlDomainTypeLabels = ControlDomainTypeLabels;

    private _unsub$ = new Subject<void>();

    constructor(private _fb: FormBuilder) {}

    get answers(): QuestionnaireAnswer[] {
        return this.questionnaire$.value?.answers;
    }

    ngOnInit(): void {
        combineLatest([this.questionnaire$, this.followupControlDomainIds$])
            .pipe(
                filter(([questionnaire]) => !this.questionnaireFormArray && !!questionnaire),
                takeUntil(this._unsub$),
            )
            .subscribe(([questionnaire, followupControlDomainIds]) =>
                this.initializeForm(questionnaire, followupControlDomainIds),
            );
    }

    attemptToContinue(): void {
        if (this.questionnaireFormArray.invalid) {
            this.scrollToFirstInvalidQuestion();
        } else {
            this.continued.emit();
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private initializeForm(questionnaire: QuestionnaireArtifact, followupControlDomainIds: number[]): void {
        const filteredAnswers = this.forFollowup
            ? questionnaire.answers.filter((a) =>
                  this.isFollowupQuestionRelevantOrAnswerBlank(a, followupControlDomainIds),
              )
            : questionnaire.answers;

        this.questionnaireFormArray = this._fb.array(
            filteredAnswers.map((answer) =>
                this._fb.group<QuestionnaireAnswerFormControls>({
                    answerType: this._fb.control(answer.answerType, Validators.required),
                    answer:
                        answer.answerType === QuestionnaireAnswerType.DONT_DO
                            ? this._fb.control(answer.answer)
                            : this._fb.control(answer.answer, Validators.required),
                }),
            ),
            { updateOn: 'change' },
        );

        this.questionnaireFormArray.controls.forEach((formGroup) =>
            formGroup.valueChanges
                .pipe(takeUntil(this._unsub$))
                .subscribe((value) => this.updateFormGroupValidatorsForSelectedAnswerType(value.answerType, formGroup)),
        );

        this.questionnaireFormArray.valueChanges
            .pipe(debounceTime(500), takeUntil(this._unsub$))
            .subscribe(() => this.updateQuestionnaire());
    }

    private updateFormGroupValidatorsForSelectedAnswerType(
        selectedAnswerType: QuestionnaireAnswerType,
        formGroup: FormGroup<QuestionnaireAnswerFormControls>,
    ): void {
        if (selectedAnswerType === QuestionnaireAnswerType.DONT_DO) {
            formGroup.controls.answer.removeValidators(Validators.required);
        } else {
            formGroup.controls.answer.addValidators(Validators.required);
        }
        formGroup.controls.answer.updateValueAndValidity({ emitEvent: false });
    }

    private updateQuestionnaire(): void {
        const questionnaireCopy = { ...this.questionnaire$.value };
        this.questionnaireFormArray.controls.forEach((answerControl, idx) => {
            const existingAnswer = questionnaireCopy.answers[idx];
            questionnaireCopy.answers[idx] = { ...existingAnswer, ...answerControl.value };
        });
        this.questionnaireUpdated.emit(questionnaireCopy);
    }

    private scrollToFirstInvalidQuestion(): void {
        this.questionnaireFormArray.markAllAsTouched();
        document.querySelector('.ng-invalid').scrollIntoView({ behavior: 'smooth' });
    }

    private isFollowupQuestionRelevantOrAnswerBlank(
        questionnaireAnswer: QuestionnaireAnswer,
        followupControlDomainIds: number[],
    ): boolean {
        return (
            // In the case of followup questionnaires, we want to include answers only if they're relevant.
            followupControlDomainIds.includes(questionnaireAnswer.controlDomainId) ||
            // Additionally, if the BE erroneously includes irrelevant questions, include any that are
            // missing an answer in order to prevent the user from being blocked by validations on submission.
            !questionnaireAnswer.answer?.length
        );
    }
}
