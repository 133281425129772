<div class="row mb-2">
    <div class="col">
        <h3 class="lead">Business Owner</h3>
    </div>
    <div class="col-auto">
        <a
            *jhiHasNotAuthority="[Roles.OrgUser]"
            exportRemovableAction
            class="text-primary"
            [routerLink]="editLink"
            [replaceUrl]="true"
        >
            Edit
        </a>
    </div>
</div>
<div class="row">
    <div class="col">
        <p class="mb-0" data-pw="rdpBusinessOwnerFullName">{{ businessOwnerName }}</p>
        <strong class="mb-0" data-pw="rdpBusinessOwnerEmail">{{ businessOwnerEmail }}</strong>
        <p class="text-muted mb-0">{{ businessOwnerBusinessUnitName }}</p>
    </div>
</div>
