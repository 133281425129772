import { Component } from '@angular/core';
import { HubspotManagementService } from './hubspot-management.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';

@Component({
    selector: 'app-hubspot-management',
    templateUrl: './hubspot-management.component.html',
    styleUrls: ['./hubspot-management.component.scss'],
})
export class HubspotManagementComponent {
    constructor(
        private _hubspotManagementService: HubspotManagementService,
        private _snackbarService: SnackbarService,
    ) {}

    syncCompaniesWithHubspot() {
        this._hubspotManagementService.syncCompaniesWithHubspot().subscribe((msg) => this.showSuccessNotification(msg));
    }

    private showSuccessNotification(successMsg: string) {
        this._snackbarService.success(successMsg);
    }
}
